import React, {useEffect, useState} from 'react'
import {IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react'
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";
import { chevronBack } from 'ionicons/icons';
import { LeaseForm } from "./LeaseForm"
import { OperationsForm } from "./OperationsForm"
import { NewPacketForm } from './NewPacketForm';

function Contracts() {

    const [driverHash, setDriverHash] = useState('')
    const [noDocFound, setNoDocFound] = useState(false);
    const [signedOperations, setSignedOperations] = useState(false);
    const [signedLease, setSignedLease] = useState(false);
    const [signedNewPacket, setSignedNewPacket] = useState(false);
    const [docURL, setDocURL] = useState("");
    const [viewModal, setViewModal] = useState("");
    const [editModal, setEditModal] = useState("");

    useEffect(() => {
        ApiService.getDriverSsn('contracts').then(it => {
            setDriverHash(it);
            openLeaseAgreement();
            openSignedAgreement();
            openNewPacket();
            checkLink(it);
        });
    }, []);

    const checkLink = (link: any) => {
        const requestOptions = {
            method: 'GET',
            headers: {}
        };
        fetch('https://westernexpressmobile.com/agreements/' + link + "-0.pdf", requestOptions)
        .then(response => {
            if (response.status === 404) {
                setNoDocFound(true);
            }
        })
        .catch(() => {
            setNoDocFound(true);
        })
    }

    const agreementHandler = () => {
        if (noDocFound) {
            toastStore.getState().setToast('No Contract Found');
        } else {
            window.open('https://westernexpressmobile.com/agreements/' + driverHash + "-0.pdf", '_blank')
        }
    }

    const openLeaseAgreement = () => {
        ApiService.agreementDataGet('lease contract', {ownerCode: localStorage.getItem('username')!, contractType: "leaseagreement"})
        .then(it => {
            setSignedLease(checkForData(it));
        })
        .catch(it => {
            console.log(it)
        })
        
    }

    const openSignedAgreement = () => {
        ApiService.agreementDataGet('operations contract', {ownerCode: localStorage.getItem('username')!, contractType: "operations"})
        .then(it => {
            setSignedOperations(checkForData(it));
        })
        .catch(it => {
            console.log(it)
        })
    }

    const openNewPacket = () => {
        ApiService.agreementDataGet('newdriver contract', {ownerCode: localStorage.getItem('username')!, contractType: "newdriver"})
        .then(it => {
            setSignedNewPacket(checkForData(it));
        })
        .catch(it => {
            console.log(it)
        })
    }

    const checkForData = (results: any) => {
        if (results.data !== undefined) {
            return true;
        } else {
            return false
        }
    }

    return (
        <IonPage style={{overflowY: "scroll"}}>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Contracts</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonModal isOpen={editModal !== ""} id="contractModal">
                    <IonToolbar color="primary">
                        <IonIcon style={{fontSize: "x-large"}} icon={chevronBack} onClick={() => setEditModal("")}/>
                    </IonToolbar>
                        {editModal === "lease" ? 
                            <LeaseForm /> : editModal === "operations" ? <OperationsForm/> : <NewPacketForm/>
                        }
                </IonModal>
                <IonList>
                    <IonItem lines="none">
                        <div className='item-container'
                             style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: "#ffffff", padding: "8px", borderRadius: "10px"}}
                             onClick={e => setEditModal("lease")}>
                            <p>Lease Agreement</p>
                            { signedLease ? <p>View</p> :
                            <p>Sign Doc</p>
                            }
                        </div>
                    </IonItem>
                    <IonItem lines="none">
                        <div className='item-container'
                             style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: "#ffffff", padding: "8px", borderRadius: "10px"}}
                             onClick={e => setEditModal("operations")}>
                            <p>Hauling Agreement</p>
                            { signedOperations ? <p>View</p> :
                            <p>Sign Doc</p>
                            }
                        </div>
                    </IonItem>
                    <IonItem lines="none">
                        <div className='item-container'
                             style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: "#ffffff", padding: "8px", borderRadius: "10px"}}
                             onClick={e => setEditModal("newpacket")}>
                            <p>New Driver Packet</p>
                            { signedNewPacket ? <p>View</p> :
                            <p>Sign Doc</p>
                            }
                        </div>
                    </IonItem>
                    <IonItem lines="none">
                        <div className='item-container'
                             style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: "#ffffff", padding: "8px", borderRadius: "10px"}}
                             onClick={agreementHandler}>
                            <p>Training Agreement</p>
                            <p>View</p>
                        </div>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    )
}

export default Contracts
