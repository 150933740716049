import React, {createRef, useEffect, useState} from "react"
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonItem, IonItemGroup, IonPage, IonTitle, IonToolbar} from "@ionic/react"
import {fill, PDFDocument} from 'pdf-lib'
import SignaturePad from "react-signature-canvas"
import ReactSignatureCanvas from "react-signature-canvas"
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";
import {useHistory} from "react-router-dom";
import { userInfo } from "os"
import { DriverProfile } from "../api/model/DriverProfile"
import { UserState, userStore } from "../api/AuthService"
import { stringify } from "querystring"
import {LeaseAgreementModule} from "../components/LeaseAgreementModule"

interface ownerData {
    address: string,
    areaCode: number,
    city: string,
    email: string,
    name: string,
    phoneNumber: number,
    state: string,
    units: any
}

const today = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)

export const LeaseForm = () => {

    const {userInfo}: { userInfo: DriverProfile } = userStore((state: UserState) => ({userInfo: state.driver}));
    const history = useHistory();
    const [lessee, setLessee] = useState<any>("");
    const [lesseeAddress, setLesseeAddress] = useState<any>("");
    const [lesseeSignature, setLesseeSignature] = useState<any>("");
    const [lesseeTitle, setLesseeTitle] = useState<any>("");
    const [showLesseeSignature, setShowLesseeSignature] = useState(true);
    const [ownerData, setOwnerData] = useState<ownerData>();
    const [unitData, setUnitData] = useState<any>();
    const [selectedTierTable1, setSelectedTierTable1] = useState<any>({});
    const [selectedTierTable2, setSelectedTierTable2] = useState<any>({});
    const [selectedTierTable3, setSelectedTierTable3] = useState<any>({});
    const [mileageStatement, setMileageStatement] = useState(0)
    const [nextFriday, setNextFriday] = useState<any>();
    const [showViewModal, setShowViewModal] = useState<boolean>(false);
    const [tableIndex, setTableIndex] = useState<any>({})
    const [viewObject, setViewObject] = useState<any>({})
    const [showContract, setShowContract] = useState<boolean>(false);
    
    const [ownerDataFull, setOwnerDataFull] = useState<boolean>(false);
    const [unitDataFull, setUnitDataFull] = useState<boolean>(false);
    const lesseeSigCanvas = createRef<ReactSignatureCanvas>()
    const [displayOnly, setDisplayOnly] = useState<boolean>(true)
    const [signatureImage, setSignatureImage] = useState<string>("");

    let currentDate = today.toISOString().split('T')[0]
    let currentYear = currentDate.slice(2, 4)


    const tierTable1 = [
        {
            Mileage: [0, 50000],
            Weekly: 744.00,
            Term: 208,
            Years: 4,
            Lease: 154752.00,
            Purchase: 38688.00,
            Total: 193044.00
        },
        {
            Mileage: [50000, 100000],
            Weekly: 695.00,
            Term: 208,
            Years: 4,
            Lease: 144560.00,
            Purchase: 36140.00,
            Total: 180700.00
        },
        {
            Mileage: [100000, 150000],
            Weekly: 625.00,
            Term: 208,
            Years: 4,
            Lease: 130000.00,
            Purchase: 32500.00,
            Total: 162500.00
        },
        {
            Mileage: [150000, 200000],
            Weekly: 577.32,
            Term: 208,
            Years: 4,
            Lease: 120082.56, 
            Purchase: 30020.64,
            Total: 150103.20
        },
        {
            Mileage: [200000, 250000],
            Weekly: 577.32,
            Term: 182,
            Years: 3.5,
            Lease: 105072.24,
            Purchase: 30200.64,
            Total: 135092.88
        },
        {
            Mileage: [250000, 300000],
            Weekly: 577.32,
            Term: 156,
            Years: 3,
            Lease: 90061.92,
            Purchase: 30200.64,
            Total: 120082.56
        },
        {
            Mileage: [300000, 349999],
            Weekly: 524.25,
            Term: 156,
            Years: 3,
            Lease: 81783.00,
            Purchase: 27261.00,
            Total: 193044.00
        },
    ]

    const tierTable2 = [
        {
            Mileage: [0, 50000],
            PDPremiums: 141.48,
            EscrowOptions: ["0.12", "0.14"],
            TotalFixed: 1053.86
        },
        {
            Mileage: [50000, 100000],
            PDPremiums: 132.17,
            EscrowOptions: ["0.14", "0.16"],
            TotalFixed: 955.55
        },
        {
            Mileage: [100000, 150000],
            PDPremiums: 119.14,
            EscrowOptions: ["0.16", "0.18"],
            TotalFixed: 912.52
        },
        {
            Mileage: [150000, 200000],
            PDPremiums: 109.83,
            EscrowOptions: ["0.18", "0.20"],
            TotalFixed: 855.53
        },
        {
            Mileage: [200000, 250000],
            PDPremiums: 100.52,
            EscrowOptions: ["0.20", "0.22"],
            TotalFixed: 846.22
        },
        {
            Mileage: [250000, 300000],
            PDPremiums: 92.15,
            EscrowOptions: ["0.22", "0.24"],
            TotalFixed: 837.85
        },
        {
            Mileage: [300000, 349999],
            PDPremiums: 83.77,
            EscrowOptions: ["0.24", "0.26"],
            TotalFixed: 776.40
        },
    ]

    const tierTable3 = [
        {
            Mileage: [0, 50000],
            Bobtail: 6.69,
            Ded: 39.23,
            HwyTax: 11.00,
            FuelTax: 37.00,
            ELD: 15.00,
            Registration: 32.00,
            OCCACC: 27.46
        },
        {
            Mileage: [50000, 100000],
            Bobtail: 6.69,
            Ded: 39.23,
            HwyTax: 11.00,
            FuelTax: 37.00,
            ELD: 15.00,
            Registration: 32.00,
            OCCACC: 27.46
        },
        {
            Mileage: [100000, 150000],
            Bobtail: 6.69,
            Ded: 39.23,
            HwyTax: 11.00,
            FuelTax: 37.00,
            ELD: 15.00,
            Registration: 32.00,
            OCCACC: 27.46
        },
        {
            Mileage: [150000, 200000],
            Bobtail: 6.69,
            Ded: 39.23,
            HwyTax: 11.00,
            FuelTax: 37.00,
            ELD: 15.00,
            Registration: 32.00,
            OCCACC: 27.46
        },
        {
            Mileage: [200000, 250000],
            Bobtail: 6.69,
            Ded: 39.23,
            HwyTax: 11.00,
            FuelTax: 37.00,
            ELD: 15.00,
            Registration: 32.00,
            OCCACC: 27.46
        },
        {
            Mileage: [250000, 300000],
            Bobtail: 6.69,
            Ded: 39.23,
            HwyTax: 11.00,
            FuelTax: 37.00,
            ELD: 15.00,
            Registration: 32.00,
            OCCACC: 27.46
        },
        {
            Mileage: [300000, 349999],
            Bobtail: 6.69,
            Ded: 39.23,
            HwyTax: 11.00,
            FuelTax: 37.00,
            ELD: 15.00,
            Registration: 32.00,
            OCCACC: 27.46
        },
    ]

    useEffect(() => {
        getAgreementData();
        getNextFriday();
        getMileage();
        ApiService.unitGet("Operations", {unitNumber: userInfo.unit!})
        .then(it => {setUnitData(it); setUnitDataFull(true)})
        ApiService.ownerGet('Contracts', {}).then(it => {setOwnerData(it); setOwnerDataFull(true)});
        // console.log("currentDate 1", currentDate);
    }, []);

    function getNextFriday() {
        let currentDay = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
        currentDay.setDate(new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).getDate() + 7);
        currentDay = new Date(currentDay);
        let tempDate = currentDay.getDay();
        let nextFriday = currentDay;
        if (tempDate <= 5) {
            nextFriday.setDate((currentDay.getDate()) + (5 - tempDate))
        } else {
            nextFriday.setDate((currentDay.getDate()) - 1)
        }
        console.log("nextFriday", nextFriday.toISOString().split('T')[0])
        setNextFriday(nextFriday.toISOString().split('T')[0])
    }

    const save = () => {
        const signed = lesseeSigCanvas.current!.toDataURL()
        setLesseeSignature(signed)
        setShowLesseeSignature(!showLesseeSignature)
    }

    const clear = () => lesseeSigCanvas.current!.clear()

    const fillForm = async () => {
        const formURL = "./assets/Lease Agreement 2024.pdf"
        const pdfFormBytes = await fetch(formURL).then((res) => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(pdfFormBytes)
        const form = pdfDoc.getForm()
        // console.log("currentDate", currentDate); 

        var OwnerName1 = form.getTextField('Owner Name 1');
        var OwnerAddress1 = form.getTextField('Owner Address 1');
        var Tier1 = form.getTextField('Tier page 1');
        var OwnerAddress2 = form.getTextField('Owner Address 2');
        var OwnerName2 = form.getTextField('Owner Name 2');
        var OwnerPhone = form.getTextField('Owner Number');
        var OwnerEmail = form.getTextField('Owner Email');
        var OwnerAddress3 = form.getTextField('Owner Address 3');
        var date1 = form.getTextField('Date 1');
        var Year1 = form.getTextField('Year 1');
        var date2 = form.getTextField('Date 2');
        var Year2 = form.getTextField('Year 2');
        var OwnerState = form.getTextField('Owner State');
        var OwnerName3 = form.getTextField('Owner Name 3');
        var Date3 = form.getTextField('Date 3');
        var Year3 = form.getTextField('Year 3');
        var UnitNumber1 = form.getTextField('Unit Number');
        var Make1 = form.getTextField('Vehicle Make/Model/Year');
        var VIN1 = form.getTextField('VIN');
        var PaymentAmount = form.getTextField('Lease Payment Amount');
        var DateofFirst = form.getTextField('Date of 1st Lease Payment');
        var NumberofPayments = form.getTextField('Number of Total Lease Payments');
        var PurchasePrice = form.getTextField('Purchase Price');
        var MileageStatement = form.getTextField('Mileage Statement')
        var Date4 = form.getTextField('Date 4');
        var OwnerName4 = form.getTextField('Owner Name 4');
        var Year4 = form.getTextField('Year 4');
        var OwnerTitle1 = form.getTextField('Owner Title 1');
        var OwnerCode1 = form.getTextField('Ownercode 1');
        var DriverCode1 = form.getTextField('Drivercode 1');
        var OwnerName5 = form.getTextField('Owner Name 5');
        var date5 = form.getTextField('Date 5');
        var Year5 = form.getTextField('Year 5');
        var OwnerTitle2 = form.getTextField('Owner Title 2');
        var OwnerCode2 = form.getTextField('Ownercode 2');
        var DriverCode2 = form.getTextField('Drivercode 2');

        OwnerName1.setText(ownerData!.name)
        OwnerName2.setText(ownerData!.name)
        Tier1.setText('Tier ' + tableIndex.toString())
        OwnerAddress1.setText(ownerData!.address + " " + ownerData!.city + " " + ownerData!.state)
        date1.setText(currentDate.slice(5,10).toString())
        OwnerAddress3.setText(ownerData!.address + " " + ownerData!.city + " " + ownerData!.state)
        OwnerAddress2.setText(ownerData!.address + " " + ownerData!.city + " " + ownerData!.state)
        OwnerName3.setText(ownerData!.name)
        OwnerPhone.setText(ownerData!.areaCode.toString() + ownerData!.phoneNumber.toString())
        OwnerEmail.setText(ownerData!.email)
        Year3.setText(currentYear.toString())
        UnitNumber1.setText(userInfo!.unit)
        date2.setText(currentDate.slice(5,10).toString())
        Year1.setText(currentYear.toString())
        PaymentAmount.setText('$' + `${selectedTierTable1.Weekly}`)
        Make1.setText(`${unitData!.unit.UNMAKE.trim()} ${unitData!.unit.UNMAKE.trim() === 'FRHT' ? 'Cascadia' : 'Prostar'} ${unitData!.unit.UNYEAR}`)
        MileageStatement.setText(Math.round(mileageStatement).toString())
        VIN1.setText(unitData!.unit.UNSER)
        OwnerName4.setText(ownerData!.name)
        OwnerName5.setText(ownerData!.name)
        Year2.setText(currentYear.toString())
        OwnerCode1.setText(localStorage.getItem('username')!)
        DriverCode1.setText(localStorage.getItem('driverCode')!)
        Date3.setText(currentDate.slice(5,10).toString())
        DateofFirst.setText(nextFriday.toString())
        OwnerState.setText(ownerData!.state)
        NumberofPayments.setText(`${selectedTierTable1.Term}`)
        PurchasePrice.setText('$' + `${selectedTierTable1.Purchase}`)
        Date4.setText(currentDate.slice(5,10).toString())
        Year4.setText(currentYear.toString())
        OwnerTitle1.setText('Independent Contractor')
        OwnerTitle2.setText('Independent Contractor')
        date5.setText(currentDate.slice(5,10).toString())
        Year5.setText(currentYear.toString())
        OwnerCode2.setText(localStorage.getItem('username')!)
        DriverCode2.setText(localStorage.getItem('driverCode')!)

        OwnerName1.enableReadOnly()
        OwnerName2.enableReadOnly()
        Tier1.enableReadOnly()
        OwnerAddress1.enableReadOnly()
        date1.enableReadOnly()
        OwnerAddress3.enableReadOnly()
        OwnerAddress2.enableReadOnly()
        OwnerName3.enableReadOnly()
        OwnerPhone.enableReadOnly()
        OwnerEmail.enableReadOnly()
        Year3.enableReadOnly()
        UnitNumber1.enableReadOnly()
        date2.enableReadOnly()
        Year1.enableReadOnly()
        PaymentAmount.enableReadOnly()
        Make1.enableReadOnly()
        MileageStatement.enableReadOnly()
        VIN1.enableReadOnly()
        OwnerName4.enableReadOnly()
        OwnerName5.enableReadOnly()
        Year2.enableReadOnly()
        OwnerCode1.enableReadOnly()
        DriverCode1.enableReadOnly()
        Date3.enableReadOnly()
        DateofFirst.enableReadOnly()
        OwnerState.enableReadOnly()
        NumberofPayments.enableReadOnly()
        PurchasePrice.enableReadOnly()
        Date4.enableReadOnly()
        Year4.enableReadOnly()
        OwnerTitle1.enableReadOnly()
        OwnerTitle2.enableReadOnly()
        date5.enableReadOnly()
        Year5.enableReadOnly()
        OwnerCode2.enableReadOnly()
        DriverCode2.enableReadOnly()

        const contractData = {
            OwnerName1: ownerData!.name,
            OwnerAddress1: ownerData!.address + " " + ownerData!.city + " " + ownerData!.state,
            date1: currentDate.slice(5,10).toString(),
            OwnerPhone: ownerData!.areaCode.toString() + ownerData!.phoneNumber.toString(),
            OwnerEmail: ownerData!.email,
            Year3: currentYear.toString(),
            UnitNumber1: userInfo!.unit,
            PaymentAmount: `${selectedTierTable1.Weekly}`,
            Make1: `${unitData!.unit.UNMAKE.trim()} ${unitData!.unit.UNMAKE.trim() === 'FRHT' ? "Cascadia" : "Prostar"} ${unitData!.unit.UNYEAR}`,
            MileageStatement: mileageStatement.toString(),
            VIN1: unitData!.unit.UNSER,
            OwnerCode1: localStorage.getItem('username')!,
            DriverCode1: localStorage.getItem('driverCode')!,
            DateofFirst: nextFriday.toString(),
            OwnerState: ownerData!.state,
            NumberofPayments: `${selectedTierTable1.Term}`,
            PurchasePrice: `${selectedTierTable1.Purchase}`,
            OwnerTitle1: "Independent Contractor",
        }

        const lesseeSignatureBytes = await fetch(lesseeSignature).then((res) => res.arrayBuffer())
        const lesseeImageSignature = await pdfDoc.embedPng(lesseeSignatureBytes)

        const pages = pdfDoc.getPages()
        const firstPage = pages[15];
        const secondPage = pages[17];
        const pngDims = lesseeImageSignature.scale(0.2)
        firstPage.drawImage(lesseeImageSignature, {
            x: firstPage.getWidth() / 2 - pngDims.width - 125,
            y: firstPage.getHeight() / 2 - pngDims.height + 320,
            width: pngDims.width,
            height: pngDims.height,
        })
        secondPage.drawImage(lesseeImageSignature, {
            x: secondPage.getWidth() / 2 - pngDims.width / 2 - 165,
            y: secondPage.getHeight() / 2 - pngDims.height / 2 + 20,
            width: pngDims.width,
            height: pngDims.height,
        })
        var sig1 = form.getTextField('Signature')
        var sig2 = form.getTextField('Signature 2')
        sig1.enableReadOnly()
        sig2.enableReadOnly()
        const usefulData = await pdfDoc.saveAsBase64({dataUri: true})
        sendAgreementData(contractData, lesseeSignatureBytes);
        submit(usefulData);
    }
 
    const b64toBlob = (base64:any) => 
        fetch(base64).then(res => res.blob())

    async function submit(data: string) {
        const newData = new Blob([(await b64toBlob(data))],{type: 'application/pdf'})
        ApiService.sendFileEmail("Lease Agreement", {
            email: `scarney@westernexp.com, mpiras@westernexp.com, lpdocuments@westernexp.com, jmorris@westernexp.com, ${ownerData!.email.trim()}`,
            subject: `Lease Agreement ${localStorage.getItem('username')}.pdf`,
        },
        {
          file1: {
            fileName: `Lease Agreement ${localStorage.getItem('username')}.pdf`,
            file: newData,
          }
        })
        .then(() => {ApiService.sendEventCommand("LeaseAgreement", "eventCheck", "User submitted an agreement")})
        .then(() => {toastStore.getState().setSuccessToast('Success. Your signature was submitted.'); redirectPage()});
        
    }

    const redirectPage = () => {
        setTimeout(function() {
          window.location.replace('./contracts');
        }, 3000)
      }

    function getAgreementData() {
        ApiService.agreementDataGet('lease contract', {ownerCode: localStorage.getItem('username')!, contractType: "leaseagreement"})
        .then(it => {
            parseFormData(it)
        })
        .catch(it => {
            console.log(it);
            setDisplayOnly(false);
        })
    }

    function parseFormData(results: any) {
        let tempData: any = {}
        if (results.data !== undefined) {
            tempData = results
            setShowContract(tempData.contractDate)
            setViewObject(JSON.parse(tempData.data))
            setSignatureImage(tempData.signatureData)
            console.log("tempData", JSON.parse(tempData.data))
            setShowViewModal(true);
        } else {
            setDisplayOnly(false);
        }
    }

    function sendAgreementData(dataBlock: any, signature: any) {
        let sigData: any = bufferToB64(signature)
        ApiService.agreementDataUpload('lease contract', {ownerCode: localStorage.getItem('username')!, contractType: "leaseagreement", contractDate: "2024-1", data: JSON.stringify(dataBlock), signatureData: sigData})
    }
    
    function bufferToB64(sigData: any) {
        const binary: any = new Uint8Array(sigData);
        let base64Data: any = `data:image/png;base64,` + btoa(String.fromCharCode(...binary));
        console.log("btoa", base64Data);
        return base64Data;
    }

    function getMileage() {
        ApiService.getUnitMileage('lease contract', {unitNumber: userInfo.unit!})
        .then(it => {
            getTiers(it)
        })
    }

    function getTiers(results: any) {
        console.log("tiers", results.mileage);
        const mileage: any = results.mileage;
        setMileageStatement(mileage);
        tierTable1.forEach((element: any) => {
            if ( element.Mileage[0] < mileage && mileage < element.Mileage[1]) {
                console.log("tier 1", element, tierTable1.indexOf(element) + 1);
                setSelectedTierTable1(element);
                setTableIndex(tierTable1.indexOf(element) + 1)
                return;
            }
        });
        tierTable2.forEach((element: any) => {
            if ( element.Mileage[0] < mileage && mileage < element.Mileage[1]) {
                console.log("tier 2", element.EscrowOptions[0]);
                setSelectedTierTable2(element);
                return;
            }
        });
        tierTable3.forEach((element: any) => {
            if ( element.Mileage[0] < mileage && mileage < element.Mileage[1]) {
                console.log("tier 3", element);
                setSelectedTierTable2(element);
                return;
            }
        });
    }

    return (
            <div style={{margin: "8px 15px", overflowY: "scroll"}}>
                <IonItem color="light" lines="none">
                    <div className="item-container">
                        <p style={{textAlign: "center", fontSize: "18px"}}>Lease Agreement</p>
                    </div>
                </IonItem>
                <IonItem color="light" lines="none">
                    { displayOnly ?
                        <div className="item-container">
                            {showViewModal ? <LeaseAgreementModule viewObject={viewObject} signatureData={signatureImage}/> : null}
                        </div> :
                        <div className="item-container">
                            {ownerDataFull && unitDataFull ? <LeaseAgreementModule ownerData={ownerData} unitData={unitData} tierTable={selectedTierTable1} mileageStatement={mileageStatement} nextFriday={nextFriday.toString()} date={currentDate.slice(5,10).toString()} year={currentYear.toString()}/> : null}
                        </div> }
                </IonItem>
                { !displayOnly ? <>
                <IonItem color="light" lines="none">
                    <div className="item-container">
                        <div className="input-container">
                            <input id="name" className="input" type="text" placeholder=" " value={lessee} onChange={e => setLessee(e.target.value!)}/>
                            <p className="placeholder">Full Name</p>
                        </div>
                    </div>
                </IonItem>
                <IonItemGroup>
                    <IonItem lines="none" color="light">
                        <div className="item-container">
                            <p>Owner Signature</p>
                            <div hidden={!showLesseeSignature} style={{textAlign: "center", width: "90%", overflow: "hidden", backgroundColor: "white", margin: "auto", borderRadius: "20px"}}>
                                <SignaturePad
                                    ref={lesseeSigCanvas}
                                    canvasProps={{width: 500, height: 175, className: 'sigCanvas'}}
                                />
                            </div>
                            <div className="item-container" style={{display: "flex", fontSize: "17px"}}>
                                {showLesseeSignature ? <p onClick={save} style={{color: "#212121", margin: "8px 10px"}}>Save</p> : <button onClick={save} className="button">Re-sign</button>}
                                {showLesseeSignature ? <p style={{color: "#5096FF", margin: "8px 10px"}} onClick={clear}>Clear</p> : null}
                            </div>
                        </div>
                    </IonItem>
                </IonItemGroup>
                <IonItemGroup>
                    <IonItem lines="none" color="light">
                        <div className="item-container">
                            <button className="western-button submit" onClick={() => {
                                if (lesseeSignature === "") {
                                    toastStore.getState().setToast('Please save signature.');
                                } else if (lessee === "") {
                                    toastStore.getState().setToast('Please complete all fields.');
                                } else {
                                    fillForm();
                                    toastStore.getState().setSuccessToast('Your form is being submitted')
                                }
                            }}>SUBMIT
                            </button>
                        </div>
                    </IonItem>
                </IonItemGroup>
                </> : null}
            </div>
    )
}

export default LeaseForm
