import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {chevronForward, close} from 'ionicons/icons';
// import {Document, Page} from 'react-pdf';
import {ApiService} from "../api/ApiService";
import {DocumentInfo} from "../api/model/DocumentsList";

const SafetyDocs: React.FC = () => {

    const [showModalDoc, setShowModalDoc] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState<DocumentInfo>();
    const [docs, setDocList] = useState<DocumentInfo[]>([]);

    useEffect(() => {
        ApiService.getDocsList('safetyDocs', {type: 'safety'}).then(it => setDocList(it));
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Help/safety"/>
                    </IonButtons>
                    <IonTitle>Safety Documentation</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonModal isOpen={showModalDoc} cssClass='my-custom-class'>
                    <IonHeader>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton onClick={function () {
                                    setShowModalDoc(false);
                                }}>
                                    <IonIcon slot="icon-only" icon={close}/>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen style={{'--color': 'black'}}>
                        <div className='doc-view'>
                            <iframe src={'https://westernexpressmobile.com/docs/'+selectedDoc?.type+'_'+selectedDoc?.id+'.'+selectedDoc?.extension+"#toolbar=0"} height= '100%' width="100%" allowFullScreen/>
                            {/* <Document file={'https://westernexpressmobile.com/docs/' + selectedDoc?.type + '_' + selectedDoc?.id + '.' + selectedDoc?.extension + "#toolbar=0"}>
                                <Page pageNumber={1}/>
                            </Document> */}
                        </div>
                    </IonContent>
                </IonModal>
                <IonList>
                    {docs.map(function (doc) {
                        let id = doc.id
                        let title = doc.title
                        return <IonItem key={id} onClick={() => {
                            setSelectedDoc(doc);
                            setShowModalDoc(!showModalDoc)
                        }}>
                            <IonLabel text-wrap>{title}</IonLabel>
                            <IonIcon slot="end" icon={chevronForward}></IonIcon>
                        </IonItem>
                    })}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default SafetyDocs;
