import React, {useEffect, useState} from 'react'
import historyIcon from '../imgs/history.png'
import preplanIcon from '../imgs/preplan.png'
import {ApiService} from "../api/ApiService";
import {Link} from "react-router-dom";

const Load = () => {

	const [hasPrePlanned, setHasPrePlanned] = useState(false)
    const [scarcityMode, setScarcityMode] = useState('0');

	useEffect(() => {
		ApiService.getUserState('Load', {name: 'scarcityMode', userId: 0}).then(it => setScarcityMode(it.value));
        ApiService.getPreplannedLoads('load').then(it => setHasPrePlanned(!!it?.length)).catch(it => console.log("no preplanneds found"))
	}, []);

    return (
        <div className='item-container'>
            <div className="grid50">
                {scarcityMode === '1' && hasPrePlanned ?
                    <Link to="/preplanned" className="western-button-link">
                        <div className="loading-div" id="centered">
                            <p style={{margin: "30% 0"}}>Preplanned</p>
                        </div>
                    </Link>
                    :
                    <Link to="/loadselection" className="western-button-link">
                        <div className="loading-div" id="centered">
                            <p style={{margin: "30% 0"}}>Load Selection</p>
                        </div>
                    </Link>}
                <div>
                    <Link to="/loadhistory" className="western-button-link">
                        <div>
                            <img src={historyIcon} alt="key" className="link-icon"/><p>Load History</p>
                        </div>
                    </Link>
                    <Link to="/currentload" className="western-button-link">
                        <div>
                            <img src={preplanIcon} alt="key" className="link-icon"/><p>Current Load</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Load
