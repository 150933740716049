import React, {useState} from "react";
import {IonBackButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonItem, IonModal, IonPage, IonTitle, IonToolbar} from "@ionic/react";

export const Disclosure: React.FC = () => {

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Consumer Disclosure</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <IonCard color="gains" className="container" style={{overflowY: "auto"}}>
                    <IonCardContent>
                        <IonItem lines="none">
                            <div className="item-container">
                                <p className="subtitle">Please read the following information, by proceeding forward and signing this document you are agreeing that you have
                                    reviewed the following consumer disclosure information and consent to transact business using electronic communications, to receive notices and
                                    disclosures electronically, and to utilize electronic signature in lieu of using paper documents. This electronic signature service is provided
                                    on behalf of clients, "sending party", whom are sending electronic documents, notices, disclosures or requesting electronic signatures to you.<br/>
                                </p>
                                <p>You are not required to receive notices and disclosures or sign documents electronically. If you prefer not to do so, you may request to recieve 
                                    paper copies and withdraw your consent at any time as described below.<br/>
                                </p><br/>
                                <h2>Paper Copies</h2><br/>
                                <p>You are not required to receive notices or disclosures or sign documents electronically and may request paper copies of documents or disclosures if you
                                    pefer to do so. You also have the ability to download and print any open or signed documents sent to you through the EchoSign electronic signature system
                                    using the PDF and Print icons. EchoSign may also email you a PDF copy of all agreements you sign using the EchoSign service. If tyou to receive paper copies
                                    in lieu of electronic documents you may close this web browser and request paper copies from the "sending party" bu following the procedures outlined below.
                                    The "sending party" may apply a charge for the requesting paper copies.<br/>
                                </p>
                                <p>Use of the Service requires a standards-compliant web-browser which supports the HTTPS protocol, HTML, and cookies. Viewing PDF documents requiring additonal 
                                    software such as Adobe Reader or similar.<br/>
                                </p><br/>
                                <h2>Withdrawal of Consent</h2><br/>
                                <p>You may withdraw your consent to receive electronic documents, notices or disclosures at any time.
                                    In order to withdraw consent you must notify the "sending party" that you wish to withdraw consent and to provide your future documents, notices, and disclosures
                                    in paper format. After withdrawing consent if at any point in the future you proceed forward and utilize the electronic signature system you are once again consenting
                                    to receive notices, disclosures, or documents electronically. You may withdraw consent to recieve electronic notices and disclosures and optionally electronically signatures
                                    by following the procedures described below.<br/>
                                </p><br/>
                                <h2>Scope of Consent</h2><br/>
                                <p>You agree to receive electronic notices, disclosures, and electronic signature documents with all related and identified documents and disclosure provided over the course of
                                    your relationship with the "sending party." You may at any point withdraw you consent by following the procedures described below.<br/>
                                </p><br/>
                                <h2>Requesting paper copies, withdrawing consent, and updating contact information</h2><br/>
                                <p>You have the ability to download and print any documents we send to you through the electronic signature system. To request paper copies of documents, withdraw consent to 
                                    conduct business electronically and recieve documents, notices, or disclosures, notices, or disclosures electronically or sign documents electronically please contact the 
                                    "sending party" with the following subjects:<br/>
                                    <b>"Requesting Paper Copies"</b> Please provide your name, email, telephone number, postal address and document title.<br/>
                                    <b>"Withdray Consent"</b> Please provide your name, email, date, telephone numberm, postal address.<br/>
                                    <b>"Update Contact Information"</b> Please provide your name, email, telephone number and postal address.<br/>
                                    Any fees associated with sending paper copies or withdrawing consent will be determined by the "sending party"
                                </p>
                            </div>
                        </IonItem>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default Disclosure;
