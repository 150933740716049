import React from 'react'
import {config} from '../Constants';
import pretripIcon from "../imgs/pretrip.png"
import breakdownIcon from "../imgs/breakdown.png"
import scanIcon from '../imgs/work/qr-scan.png';
import passengerIcon from "../imgs/add-passenger.png"
import inspectionIcon from "../imgs/doc-check.png"
import {AppLauncher} from '@capacitor/app-launcher';
import {ApiService} from "../api/ApiService";
import {userStore} from "../api/AuthService";
import {Link} from "react-router-dom";

const Trip = () => {

    const transfloClick = () => {
        ApiService.sendEventCommand('Tranflo Route', 'eventCheck', 'User has been routed to the Transflo app');
        const deepLink = `transflomobileplus://scan?recipientid=WSXI&sourceapp=westerndrivermobile&doctypes=TOD,BOL,TRP&driverid=${localStorage.getItem('username')?.toUpperCase()}&truckid=${userStore.getState().driver.unit}`;
        AppLauncher.openUrl({url: deepLink});
    }

    return (
        <div className="item-container">
            <div className="grid50">
                <Link to="/trailertracking" className="image-button">
                    <div id="stock-map">
                        <p>Trailer Tracking</p>
                    </div>
                </Link>
                <Link to="/photoupload" className="image-button">
                    <div id="last-tarp">
                        <p>Tarp Photo</p>
                    </div>
                </Link>
                <div className="western-button-link" onClick={() => transfloClick()}>
                    <div id="centered">
                        <p style={{textAlign: "left"}}>Submit Paperwork<br/><span style={{color: "#D0A024", fontSize: "12px"}}>Transflo</span></p>
                        <img src={scanIcon} alt="scan"/>
                    </div>
                </div>
                <div>
                    <Link className="western-button-link" to="/passenger">
                        <div>
                            <img src={passengerIcon} alt="key" className="link-icon"/><p>Add Passenger</p>
                        </div>
                    </Link>
                    <Link className="western-button-link" to="/leasepurchase">
                        <div>
                            <img src={inspectionIcon} alt="key" className="link-icon"/><p>Vehicle Inspection<br/><span style={{color: "#D0A024", fontSize: "12px"}}>Lease Purchase Program Only</span>
                        </p>
                        </div>
                    </Link>
                </div>
            </div>
            <p style={{margin: "0 8px", color: "#5096FF"}}>Pretrip and breakdowns must be completed on the Platform Science tablet.</p>
        </div>
    )
}

export default Trip
