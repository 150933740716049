import React, {useState} from "react"
import {IonContent, IonFooter, IonHeader, IonItem, IonList, IonModal, IonPage, IonToolbar} from "@ionic/react"
import Nav from "./Nav"
import Toolbar from "./Toolbar"

import logoutIcon from "../imgs/logout.png"
import {AppLauncher} from "@capacitor/app-launcher"
import {Markup} from "interweave"
import {ApiService} from "../api/ApiService";
import {useHistory} from "react-router-dom";

const Settings = () => {

    const history = useHistory();

    const [showModal, setShowModal] = useState(false)
    const [doc, setDoc] = useState({doc: '', title: ''})

    const openDoc = (idx: number) => {
        setShowModal(true);
        setDoc(docs[idx]);
    }

    let docs = [
        {
            title: 'Terms and Conditions', doc: `<div>
    
        <p>General Terms and Conditions of LP Pro App</p>
        
        <p><i>updated June 7, 2022</i></p>
        
        <p >1. Consent. By accessing, using or downloading any materials through the 
        LP Pro App (the “Application”), you agree to be bound by and consent 
        to the following terms and conditions for electronic signature (the “Consent”). 
        If you do not agree to the terms of this Consent, you are not authorized and must 
        cease using the Application immediately; provided, once you have consented to the 
        terms of this Consent, such consent may only be withdrawn as set forth in Section 
        3(b) below. </p>
    
        <p>2.	Scope. These Terms are the terms of the agreement between New Horizons. 
        (the “Company”) and all users of the Application other than employees or independent 
        contractors of New Horizons. using the Application in their capacity as such 
        (“Company Personnel”). If you are Company Personnel, your use of the Application is 
        governed by the terms provided in your employee documentation and training.</p>
        
        <p>3. Revisions. We may supplement, modify or revise the terms
        of these Terms or any additional terms and conditions that are relevant to the
        use of the Application from time to time in order to reflect changes in the law
        or to the Application, including without limitation the Consent To Electronic
        Signatures for LP Pro App and our Privacy Policy. We will post
        such revised Terms or such additional terms and conditions on the Application
        with a "last updated" date. PLEASE REVIEW THE APPLICATION ON A REGULAR BASIS TO
        OBTAIN TIMELY NOTICE OF ANY REVISIONS. IF YOU CONTINUE TO USE THE APPLICATION
        AFTER THE REVISIONS TAKE EFFECT, YOU AGREE TO BE BOUND BY SUCH REVISED TERMS
        AND CONDITIONS. You agree that we shall not be liable to you or to any third
        party for any modification of the Terms or such additional terms and
        conditions.</p>
        
        <p>4. Communications. You agree to receive all communications,
        agreements, and notices that we provide in connection with the Application
        ("Communications"), including, but not limited to, Communications related to
        the Application, via electronic means, including by e-mail, text, in-app
        notifications, or by posting them on the Application. You agree that all
        Communications we provide to you electronically satisfy any legal requirement
        that such Communications be in writing or be delivered in a particular manner
        and you agree to keep your account contact information current.</p>
        
        <p>5. Use and Access Rights. You represent and warrant that
        you are of legal age and competent to agree to these Terms. Upon your
        acceptance of the Terms as set forth herein, we grant you a limited,
        non-exclusive license to access and use the Application as expressly permitted
        by these Terms. You shall not use or permit use of the Application for any
        illegal purpose or in any manner inconsistent with the provisions of these
        Terms. Except as otherwise restricted by these Terms, we grant you permission
        on a non-exclusive, non-transferable, limited basis to display, copy, and
        download content and materials on the Application provided that you: (a) retain
        all copyright and other proprietary notices on the content and materials; (b)
        use them solely for personal or internal, non-commercial use or in accordance
        with your employment by NEW HORIZON Express or its affiliates; and (c) do not
        modify them in any way. Any violation by you of the license provisions
        contained in this Section 4 may result in the immediate termination of your
        right to use the Application, as well as potential liability for copyright
        infringement or other claims depending on the circumstances.</p>
        
        <p>6. Intellectual Property. The Application is a proprietary
        collective work or compilation protected by, and contains materials that are
        proprietary and are protected by, copyright laws, international treaties,
        trademarks, service marks, and other intellection property laws, treaties and
        regulations. You acknowledge and agree that all such work and materials are the
        exclusive property of New Horizons. and it affiliates. You agree to
        abide by all applicable copyright and other laws and all notices or
        restrictions contained in the Application. You acknowledge and agree that
        usage of trademarks, logos, taglines and service marks in the Application is
        strictly prohibited. You acknowledge and agree that all software provided in
        and through the Application is copyrighted and its use is governed by these
        Terms, and reproduction or distribution of such software is expressly
        prohibited.</p>
        
        <p>7. Submissions. Submission of information in the
        Application constitutes the granting of a non-exclusive, royalty-free, and
        fully sublicensable right to access, view, use, reproduce, modify, adapt,
        publish, translate, create derivative works from, distribute, copy, and display
        such information throughout the world in any form, media, or technology now
        known or hereafter developed. You represent and warrant that: (a) you own or
        otherwise control all of the rights to such information; (b) such information
        is truthful and accurate; and (c) use of such information does not violate
        these Terms or any applicable laws. </p>
        
        <p>8. Screening and Removal. You acknowledge and agree that
        New Horizons. and its designees may or may not, at its discretion,
        pre-screen any information submitted before its appearance on the Application,
        but that New Horizons. has no obligation to do so. You further
        acknowledge and agree that New Horizons. reserves the right (but does
        not assume the obligation) in its sole discretion to reject, move, edit, or
        remove any information that is contributed to the Application. Without limiting
        the foregoing, New Horizons. and its designees shall have the right to
        remove any information submitted that violates these Terms or is otherwise
        objectionable in New Horizons.'s sole discretion. You acknowledge and
        agree that New Horizons. does not verify, adopt, ratify, or sanction
        any information submitted, and you agree that you must evaluate and bear all
        risks associated with your use of such information or your reliance on the
        accuracy, completeness, or usefulness of such information.</p>
        
        <p>9. Use Restrictions.</p>
        
        <p>a. By using the Application, you specifically agree not to
        engage in any activity or transmit any information that, in LP Pro 
        sole discretion:</p>
        
        <p>i. Is illegal, or violates any federal, state, or local law
        or regulation;</p>
        
        <p>ii. Advocates illegal activity or discusses illegal
        activities with the intent to commit them;</p>
        
        <p>iii. Violates any third-party right, including, but not
        limited to, right of privacy, right of publicity, copyright, trademark, patent,
        trade secret, or any other intellectual property or proprietary rights;</p>
        
        <p>iv. Is harmful, threatening, abusive, harassing, tortious,
        indecent, defamatory, sexually explicit or pornographic, discriminatory,
        vulgar, profane, obscene, libelous, hate speech, violent or inciting violence,
        inflammatory, or otherwise objectionable;</p>
        
        <p>v. Interferes with any other party's use and enjoyment of
        the Application;</p>
        
        <p>vi. Attempts to impersonate another person or entity;</p>
        
        <p>vii. Is commercial in a way that violates these Terms,
        including but not limited to, using the Application for spam, surveys,
        contests, pyramid schemes, or other advertising materials;</p>
        
        <p>viii. Falsely states, misrepresents, or conceals your
        affiliation with another person or entity;</p>
        
        <p>ix. Accesses or uses the account of another user without
        permission;</p>
        
        <p>x. Distributes computer viruses or other code, files, or
        programs that interrupt, destroy, or limit the functionality of any computer
        software or hardware or electronic communications equipment;</p>
        
        <p>xi. Interferes with, disrupts, disables, overburdens, or
        destroys the functionality or use of any features of the Application, or the
        servers or networks connected to the Application;</p>
        
        <p>xii. "Hacks" or accesses without permission our proprietary
        or confidential records, those of another user, or those of anyone else;</p>
        
        <p>xiii. Improperly solicits personal or sensitive information
        from other users including without limitation address, credit card or financial
        account information, or passwords;</p>
        
        <p>xiv. Decompiles, reverse engineers, disassembles, or
        otherwise attempts to derive source code from the Site, except as expressly
        permitted by these Terms or by law, unless and then only to the extent
        permitted by applicable law without consent;</p>
        
        <p>xv. Removes, circumvents, disables, damages, or otherwise
        interferes with security-related features, or features that enforce limitations
        on use of the Application;</p>
        
        <p>xvi. Uses automated or manual means to violate the
        restrictions in any robot exclusion headers on the Application, if any, or
        bypasses or circumvents other measures employed to prevent or limit access, for
        example by engaging in practices such as "screen scraping," "database
        scraping," or any other activity with the purpose of obtaining lists of users
        or other information;</p>
        
        <p>xvii. Modifies, copies, scrapes or crawls, displays,
        distributes, publishes, licenses, sells, rents, leases, lends, transfers, or
        otherwise commercializes any materials or content on the Application;</p>
        
        <p>xviii. Uses the Application for benchmarking, or to compile
        information for a product or service;</p>
        
        <p>xix. Downloads (other than through page caching necessary
        for personal use, or as otherwise expressly permitted by these Terms),
        distributes, posts, transmits, performs, reproduces, broadcasts, duplicates,
        uploads, licenses, creates derivative works from, or offers for sale any
        content or other information contained on or obtained from or through the
        Application, by any means except as provided for in these Terms or with the
        prior written consent of an authorized agent of New Horizons.; or</p>
        
        <p>xx. Attempts to do any of the foregoing.</p>
        
        <p>b. If you believe content on the Application violates the
        above restrictions, please contact us.</p>
        
        <p>c. In addition, you shall not, and shall not permit others
        to, do the following with respect to the Application:</p>
        
        <p>i. Use the Application or allow access to it in a manner
        that circumvents contractual usage restrictions, violates the terms and
        provisions of our Privacy Policy then in effect or your Consent to Electronic
        Signature then in effect; </p>
        
        <p>ii. License, sub-license, sell, re-sell, rent, lease,
        transfer, distribute or time share or otherwise make any portion of the
        Application or related then-current technical and functional documentation for
        the Application available for access by third parties except as otherwise
        expressly provided in these Terms; or</p>
        
        <p>iii. Access or use the Application or related documentation
        for the purpose of developing or operating products or services intended to be
        offered to third parties in competition with Application or allow access by a
        direct competitor of New Horizons.</p>
        
        <p>d. You acknowledge that we have no obligation to monitor
        your or anyone else's access to or use of the Application for violations of
        these Terms, or to review or edit any content. However, we have the right to do
        so for the purpose of operating and improving the Application (including
        without limitation for fraud prevention, risk assessment, investigation and
        customer support purposes, analytics, and advertising), to ensure your
        compliance with these Terms and to comply with applicable law or the order or
        requirement of a court, consent decree, administrative agency or other
        governmental body.</p>
        
        <p>10. Termination. New Horizons may suspend or terminate
        your use of the Application and deny you access to the Application in its sole
        discretion and for any reason or no reason, including without limitation for
        violation of these Terms. Termination shall not vitiate your Consent to
        Electronic Signatures unless withdrawn pursuant to the terms and conditions set
        forth therein.</p>
        
        <p>11. Warranties and Disclaimers. THE APPLICATION AND RELATED
        MATERIALS ARE PROVIDED "AS IS" AND "AS AVAILABLE." YOUR USE OF THE APPLICATION
        SHALL BE AT YOUR SOLE RISK. NEW HORIZON'S. AND ITS RESPECTIVE OFFICERS,
        DIRECTORS, EMPLOYEES, MEMBERS, SHAREHOLDERS, AGENTS, AFFILIATES, SUBSIDIARIES,
        AND LICENSORS ("NEW HORIZON'S PARTIES"): (a) MAKES NO ADDITIONAL REPRESENTATION OR
        WARRANTY OF ANY KIND WHETHER EXPRESS, IMPLIED (EITHER IN FACT OR BY OPERATION
        OF LAW), OR STATUTORY, AS TO ANY MATTER WHATSOEVER; (b) EXPRESSLY DISCLAIMS ALL
        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
        QUALITY, ACCURACY, AND TITLE; AND (c) DOES NOT WARRANT THAT THE APPLICATION AND
        RELATED MATERIALS ARE OR WILL BE ERROR-FREE, WILL MEET YOUR REQUIREMENTS, OR BE
        TIMELY OR SECURE. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE RESULTING FROM
        USE OF THE APPLICATION OR RELATED MATERIALS.</p>
        
        <p>THE NEW HORIZON'S PARTIES MAKE NO WARRANTIES OR REPRESENTATIONS
        THAT THE APPLICATION AND RELATED MATERIALS HAVE BEEN AND WILL BE PROVIDED WITH
        DUE SKILL, CARE AND DILIGENCE OR ABOUT THE ACCURACY OR COMPLETENESS OF THE
        APPLICATION CONTENT AND ASSUMES NO RESPONSIBILITY FOR ANY: (i) ERRORS,
        MISTAKES, OR INACCURACIES OF CONTENT; (ii) PERSONAL INJURY OR PROPERTY DAMAGE
        OF ANY NATURE WHATSOEVER RESULTING FROM YOUR ACCESS TO AND USE OF THE
        APPLICATION; (iii) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR ANY
        AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN; (iv)
        ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE APPLICATION; (v)
        ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR
        THROUGH THE APPICATIONS THROUGH THE ACTIONS OF ANY THIRD PARTY; (vi) ANY LOSS
        OF YOUR DATA OR CONTENT FROM THE SITE; AND/OR (vii) ANY ERRORS OR OMISSIONS IN
        ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
        USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
        VIA THE APPLICATION. YOU WILL NOT HAVE THE RIGHT TO MAKE OR PASS ON ANY
        REPRESENTATION OR WARRANTY ON BEHALF OF THE NEW HORIZON PARTIES TO ANY THIRD PARTY.
        BECAUSE SOME STATES AND JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED
        WARRANTIES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN THAT EVENT, SUCH
        WARRANTIES ARE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY AND FOR THE MINIMUM
        WARRANTY PERIOD ALLOWED BY THE MANDATORY APPLICABLE LAW.</p>
        
        <p>THE NEW HORIZON PARTIES DO NOT WARRANT, ENDORSE, GUARANTEE, OR
        ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
        THIRD PARTY THROUGH THE APPLICATION, AND THE NEW HORIZON PARTIES WILL NOT BE A
        PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
        YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. YOU ARE SOLELY
        RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH OTHER PERSONS
        WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE
        APPLICATION.</p>
        
        <p>If you are a California resident, you hereby waive
        California Civil Code §1542, which says: "A general release does not extend to
        claims which the creditor does not know or suspect to exist in his favor at the
        time of executing the release, which if known by him must have materially
        affected his settlement with the debtor." This release includes the criminal
        acts of others.</p>
        
        <p>Some jurisdictions do not allow the exclusion of certain
        warranties or the limitation or exclusion of liability for incidental or
        consequential damages such as in this Section 10 or below in Section 12.
        Accordingly, some of these limitations may not apply to you. </p>
        
        <p>12. Indemnification. You will defend, indemnify, and the
        NEW HORIZON Parties and each of their respective officers, directors, employees,
        suppliers, consultants, and agents harmless from any and all third-party
        claims, liability, damages, and costs (including, but not limited to,
        attorneys' fees) arising from or related to, as applicable: (a) your access to
        and use of the Application; (b) violation of these Terms by you or users
        accessing the Application via your login credentials; (c) infringement of any
        intellectual property or other right of any person or entity by you; (d) the
        nature and content of all information processed by the Application; or (e) any
        products or services purchased or obtained by you in connection with the
        Application.</p>
        
        <p>The NEW HORIZON Parties retain their respective exclusive right
        to settle, compromise, and pay, without your prior consent, any and all claims
        or causes of action that are brought against them. The NEW HORIZON Parties reserve
        the right, at your expense, to assume the exclusive defense and control of any
        matter for which you are required to indemnify such NEW HORIZON Party and you agree
        to cooperate with our defense of these claims. You agree not to settle any
        matter in which any NEW HORIZON Party is named as a defendant and/or for which you
        have indemnity obligations without our prior written consent. The NEW HORIZON
        Parties will use reasonable efforts to notify you of any such claim, action, or
        proceeding upon becoming aware of it.</p>
        
        <p>13. Consequential Damage Waiver. NOTWITHSTANDING ANYTHING
        TO THE CONTRARY CONTAINED IN THESE TERMS, NO NEW HORIZON PARTY WILL, UNDER ANY
        CIRCUMSTANCES, BE LIABLE TO YOU FOR ANY CONSEQUENTIAL, INCIDENTAL, SPECIAL,
        COVER, PUNITIVE, OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATED TO THE
        TRANSACTIONS CONTEMPLATED UNDER THESE TERMS, INCLUDING, BUT NOT LIMITED TO,
        GOODWILL, WORK STOPPAGE, LOST PROFITS, OR LOSS OF BUSINESS, EVEN IF APPRISED OF
        THE LIKELIHOOD OF SUCH LOSSES, AND WHETHER SUCH CLAIMS ARE MADE BASED ON CONTRACT,
        TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY.</p>
        
        <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO
        EVENT SHALL THE NEW HORIZON PARTIES BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT,
        INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING
        FROM ANY: (a) USE OF THE APPLICATION OR RELATED MATERIALS; (b) ERRORS,
        MISTAKES, OR INACCURACIES OF CONTENT; (c) PERSONAL INJURY OR PROPERTY DAMAGE OF
        ANY NATURE WHATSOEVER RESULTING FROM YOUR ACCESS TO AND USE OF THE APPLICATION
        OR RELATED MATERIALS; (d) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS
        AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
        THEREIN; (e) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR
        SERVERS; (f) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE
        TRANSMITTED TO OR THROUGH THE APPLICATION BY ANY THIRD PARTY; (g) ANY LOSS OF
        YOUR DATA OR CONTENT FROM THE APPLICATION; (h) ANY ERRORS OR OMISSIONS IN ANY
        CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE
        OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
        APPLICATION, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
        OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE NEW HORIZON PARTIES ARE ADVISED
        OF THE POSSIBILITY OF SUCH DAMAGES; AND/OR (i) THE DISCLOSURE OF INFORMATION
        PURSUANT TO THESE TERMS, YOUR CONSENT TO ELECTRONIC SIGNATURES OR OUR PRIVACY
        POLICY.</p>
        
        <p>Some states, countries and jurisdictions do not allow the
        limitation or exclusion of consequential, direct, indirect, or other damages in
        contracts with consumers and to the extent you are a consumer the limitations
        or exclusions in this Section 12(a) may not apply to you. In that event, such
        exclusions and limitations shall apply to the maximum extent permitted by
        applicable mandatory law (and our liability shall be limited or excluded as
        permitted under mandatory applicable law)</p>
        
        <p>14. Additional Provisions. </p>
        
        <p>a. Assignability. You may not assign your rights or
        obligations under these Terms without New Horizons.'s prior written
        consent. If consent is given, these Terms will bind your successors and
        assigns. Any attempt by you to transfer your rights, duties, or obligations
        under these Terms except as expressly provided by these Terms is void. NEW HORIZON
        Express, Inc. may freely assign its rights, duties, and obligations under these
        Terms.</p>
        
        <p>b. Notices. Any notice required or permitted to be given in
        connection with the Application and other than as set forth in Section 3 above,
        will be effective only if it is in writing and sent using: (a) by NEW HORIZON
        Express, Inc., the Application, with effectiveness upon sending; or (b) by
        you, certified or registered mail to NEW HORIZON Express (Attention: Legal
        Department), 7135 Centennial Place, Nashville, TN 37209, with effectiveness
        upon receipt. New Horizons. may change its address for receipt of
        notice by notice to you in accordance with this Section 13(b).</p>
        
        </div>`
        },
        {
            title: 'Privacy Policy', doc: `
        
                <p>New Horizons built the LP Pro Mobile App app as a Free app. This SERVICE is provided by New Horizons at no cost and is intended for use as is.</p>
                <p>This page is used to inform visitors regarding our policies with the collection, use, and
                disclosure of Personal Information if anyone decided to use our Service.</p>
                <p>If you choose to use our Service, then you agree to the collection and use of information in relation
                to this policy. The Personal Information that we collect is used for providing and improving the
                Service. We will not use or share your information with anyone except as described
                in this Privacy Policy.</p>
                <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at LP Pro Mobile App unless otherwise defined in this Privacy Policy.</p>
        
                <p><strong>Information Collection and Use</strong></p>
                <p>For a better experience, while using our Service, we may require you to provide us with certain
                personally identifiable information, including but not limited to the advertising id, camera data, and location information. The information that we request will be retained by us and used as described in this privacy policy.</p>
                <p>The app does use third party services that may collect information used to identify you.</p>
        
                <div>
                       <p>Link to privacy policy of third party service providers used by the app</p>
                       <ul>
                               <li><a href="https://www.google.com/policies/privacy/" target="_blank">Google Play Services</a></li>
                       </ul>
                </div>
        
                <p><strong>Log Data</strong></p>
                <p>We want to inform you that whenever you use our Service, in a case of an
                error in the app we collect data and information (through third party products) on your phone
                called Log Data. This Log Data may include information such as your device Internet Protocol ("IP") address,
                device name, operating system version, the configuration of the app when utilizing our Service,
                the time and date of your use of the Service, and other statistics.</p>
        
                <p><strong>Cookies</strong></p>
                <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These
                are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>
                <p>This Service does not use these "cookies" explicitly. However, the app may use third party code and libraries that use "cookies" to collect information and improve their services. You have the option to either
                accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to
                refuse our cookies, you may not be able to use some portions of this Service.</p>
        
                <p><strong>Service Providers</strong></p>
                <p>We may employ third-party companies and individuals due to the following reasons:</p>
                <ul>
                   <li>To facilitate our Service;</li>
                   <li>To provide the Service on our behalf;</li>
                   <li>To perform Service-related services; or</li>
                   <li>To assist us in analyzing how our Service is used.</li>
                </ul>
                <p>We want to inform users of this Service that these third parties have access to your
                Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they
                are obligated not to disclose or use the information for any other purpose.</p>
        
                <p><strong>Security</strong></p>
                <p>We value your trust in providing us your Personal Information, thus we are striving
                to use commercially acceptable means of protecting it. But remember that no method of transmission over
                the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee
                its absolute security.</p>
        
                <p><strong>Links to Other Sites</strong></p>
                <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed
                to that site. Note that these external sites are not operated by us. Therefore, we strongly
                advise you to review the Privacy Policy of these websites. We have no control over
                and assume no responsibility for the content, privacy policies, or practices of any third-party sites
                or services.</p>
        
                <p><strong>Children's Privacy</strong></p> <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from
                our servers. If you are a parent or guardian and you are aware that your child has provided us with personal
                information, please contact us so that we will be able to do necessary actions.</p>
        
                <p><strong>Changes to This Privacy Policy</strong></p>
                <p>We may update our Privacy Policy from time to time. Thus, you are advised to review
                this page periodically for any changes. We will notify you of any changes by posting
                the new Privacy Policy on this page. These changes are effective immediately after they are posted on
                this page.</p>
        
                <p><strong>Contact Us</strong></p>
                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
      
      `
        },
        {
            title: 'Consent to Electronic Signatures', doc: `<p>Consent To Electronic Signatures for LP Pro
        Mobile App</p>
        
        <p><i>updated June 6, 2022</i></p>
        
        <p>1.	Consent. By accessing, using or downloading any materials through the 
        LP Pro App (the “Application”), you agree to be bound by and 
        consent to the following terms and conditions for electronic signature (the “Consent”).
          If you do not agree to the terms of this Consent, you are not authorized and 
          must cease using the Application immediately; provided, once you have consented 
          to the terms of this Consent, such consent may only be withdrawn as set forth in 
          Section 3(b) below.  </p>
        
        <p>2.	Terms and Conditions.  The applicable terms and conditions, whether the General 
        Terms and Conditions available on the Application or the terms and conditions provided 
        to you as part of your employee training and documentation (“Other Terms”), are incorporated 
        herein as if reproduced in full; provided, however, to the extent the terms of the Consent 
        inconsistent with the Other Terms, the terms and conditions of this Consent shall control. 
        Any reference to the “Terms” in such Other Terms shall be deemed to include a reference to 
        this Consent in addition to the Other Terms.</p>
        
        <p>3. Disclosures and Procedures. In connection with your
        authorizing this Consent, you acknowledge and agree to the following terms:</p>
        
        <p>a.	Copy Requests. You may request and have the right to receive paper copies of documents you electronically 
        sign through the Application in hard copy by delivering a request via prepaid 
        certified mail to New Horizons. (Attention:  Legal Department), 7135 Centennial Place, 
        Nashville, TN 37209.  Such notice shall be signed by you and shall include the delivery location 
        for such requested copies.  Copies shall be at no charge, but New Horizons. reserves the 
        right to charge for delivery costs.</p>
        
        <p>b.	Withdrawal of Consent.  You may withdraw your consent at any time by delivering 
        written notice via certified mail to, which notice shall be effective upon receipt by, 
        New Horizons. (Attention:  Legal Department), 7135 Centennial Place, Nashville, 
        TN 37209.  Such notice shall be signed by you.  Withdrawal of such consent (i) shall 
        not vitiate any signatures provided pursuant to the terms of this Consent prior to the 
        effectiveness of such notice of withdrawal.  Withdrawal of your consent may be grounds 
        for New Horizons. (in its sole discretion) to terminate your access to the Application.</p>
        
        <p>c.	Scope of Consent.  This Consent applies to any and all documents you electronically 
        sign using the Application (the “Signed Documents”)..</p>
        
        <p>d.	Contact Information.  You may modify your email contact information via the Application, 
        via email to your driver manager, or via prepaid certified mail to New Horizons. 
        (Attention:  Legal Department), 7135 Centennial Place, Nashville, TN 37209.</p>
        
        <p>e. System Requirements. The system requirements for
        access and retention of the Signed Documents are a recent ANDROID or IOS
        capable phone or tablet that is able to run the Application.</p>
        
        <p>f. Access to Signed Documents. You will have an
        opportunity to download electronic copies of Signed Documents upon submission.
        To the extent you do not download such Signed Documents, you may request hard
        copies as set forth in Section 3(a) herein or may make a request for electronic
        copies via email to your driver manager; provided, however, you acknowledge and
        agree (i) that the retention of Signed Documents by New Horizons. is
        subject to applicable law and any document retention policies and procedures in
        place from time to time, and (ii) the Signed Documents may not be retained by
        New Horizons. beyond such periods referred to in the preceding subsection
        (i).</p>
        
        <p>4. Personal Information. Before we can accept your
        personal information and allow you to sign and submit documents electronically,
        you need to be aware of the following information and must affirmatively agree
        to the following:</p>
        
        <p>a. Your responses to questions throughout use of the
        Application will be recorded and made part of your electronically signed
        documents.</p>
        
        <p>b. As part of the use of application, you will be
        required to create a log-in and password for future use, consisting of your
        unique username and a password specifically chosen by you. You must keep your
        password confidential as it can be used to electronically sign additional
        documents.</p>
        
        <p>c. Subject to Section 3(f) above, you can download the
        Signed Documents upon submission to review, save, and print the documents you
        signed electronically.</p>
        
        <p>d. You have read and agree with the verification and
        security procedures in our Privacy Policy (the "Privacy Policy"), the General
        Terms, and this Consent.</p>
        
        <p>5. Electronic Signatures. By signing up for an account
        to use the Application and using the Application, you acknowledge and agree to
        all of the following:</p>
        
        <p>a. You have read and understood the terms and conditions
        in the Privacy Policy, the General Terms, and this Consent;</p>
        
        <p>b. You agree that your electronic signature is the
        equivalent of a manual signature and that we may rely on it as such in
        connection with any and all agreements you may enter into using the Application
        and for all Signed Documents;</p>
        
        <p>c. You acknowledge and agree that it is your obligation
        to immediately advise us of any change in your electronic address (i.e., email
        address) as set forth in Section 3(d) above;</p>
        
        <p>d. You acknowledge and agree that it is your obligation
        to immediately advise us in accordance with Section 3(b) above to the that you
        intend to withdraw your consent to use electronic means for signatures in
        connection with use of Application;</p>
        
        <p>e. You agree to the Consent set forth herein;</p>
        
        <p>f. You acknowledge and agree that the various security
        and verification procedures adopted by us in the General Terms and Privacy
        Policy in connection with the Signed Documents constitute reasonable and secure
        security procedures; and</p>
        
        <p>g. f.	You acknowledge and agree that in the event that any person known to you 
        (whether it be a family member, member of your household, trainer, trainee, 
          co-driver, agent or otherwise) accesses your account without authorization from 
          you and such unauthorized access could not reasonably be detected by us, we shall 
          have the right to treat all resulting electronic signatures as though they were 
          affixed by you.</p>
        
        </div>`
        }
    ]
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <Toolbar/>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <IonModal isOpen={showModal} cssClass='my-custom-class'>
                    <IonHeader>
                        <IonToolbar>
                            <p style={{padding: "0 8px"}} onClick={function () {
                                setShowModal(false);
                                setDoc({doc: '', title: ''})
                            }}>Back</p>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <IonItem lines="none">
                            <div className='item-container'>
                                <h1>{doc.title}</h1>
                                <Markup content={doc.doc}/>
                            </div>
                        </IonItem>
                    </IonContent>
                </IonModal>
                <IonItem lines="none" color="light">
                    <div className="item-container"><h3><strong>Settings</strong></h3></div>
                </IonItem>
                <IonList lines="none" color="light" style={{padding: 0}}>

                    <IonItem color="light" lines="none">
                        <div className="item-container">
                            <div className="western-button-link" onClick={() => ApiService.userLogout('settings')}>
                                <div className="red">
                                    <img src={logoutIcon} alt="key" className="link-icon"/><p>Logout</p>
                                </div>
                            </div>
                        </div>
                    </IonItem>
                    <IonItem color="light" lines="none">
                        <div className="item-container">
                            <div className="western-button-link" onClick={() => AppLauncher.openUrl({url: 'market://details?id=com.lppro.mobile'})}>
                                <div>
                                    <p>App Version: 3.7.10</p>
                                </div>
                            </div>
                        </div>
                    </IonItem>
                    <IonItem lines="none" color="light">
                        <div className='item-container'>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <p>
                                    <button className={"docbtn"} onClick={() => openDoc(0)}>Terms and Conditions.</button>
                                </p>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <p>
                                    <button className={"docbtn"} onClick={() => openDoc(1)}>Privacy Policy.</button>
                                </p>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <p>
                                    <button className={"docbtn"} onClick={() => openDoc(2)}>Consent to Electronic Signatures.</button>
                                </p>
                            </div>

                        </div>
                    </IonItem>
                    <IonItem color="light" lines="none">
                        <div className="item-container">
                            <p className="subtitle" style={{fontSize: "x-small"}}>Icons from icons8.com</p>
                        </div>
                    </IonItem>
                </IonList>
            </IonContent>
            <IonFooter>
                <Nav onSearch={() => history.push('/home?search=true')}/>
            </IonFooter>
        </IonPage>
    )
}

export default Settings
