import React, {createRef, useEffect, useState} from 'react';
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import SignaturePad from "react-signature-canvas"
import ReactSignatureCanvas from "react-signature-canvas"
import {close} from 'ionicons/icons';
import {PDFDocument} from 'pdf-lib'
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";

interface AdvanceHistory {
    date: string,
    amount: string,
    approved: any
}

interface ownerData {
    address: string,
    areaCode: number,
    city: string,
    email: string,
    name: string,
    phoneNumber: number,
    state: string,
    units: any
}

const Deferment: React.FC = () => {

    const [advances, setAdvances] = useState<AdvanceHistory[]>([]);
    const [currentDeferments, setCurrentDeferments] = useState<AdvanceHistory[]>([]);
    const [lesseeSignature, setLesseeSignature] = useState<any>("");
    const [showSigning, setShowSigning] = useState(false);
    const [header] = useState<string>();
    const [ownerData, setOwnerData] = useState<ownerData>();
    const [owesWestern] = useState(false);
    const [showLesseeSignature, setShowLesseeSignature] = useState(true);
    const [defermentModal, setDefermentModal] = useState(false);
    const lesseeSigCanvas = createRef<ReactSignatureCanvas>();
    const [deferLink, setDeferLink] = useState("");

    const today = new Date().toLocaleString().slice(0,10).replace(',','')

    useEffect(() => {
        ApiService.defermentsGet('deferment', {}).then(it => processAdvanceHistory(it));
        ApiService.ownerGet('deferment', {}).then(it => setOwnerData(it));
    }, []);

    const b64toBlob = (base64: any) =>
        fetch(base64).then(res => res.blob())

    async function parsePDF(idNumber: number) {
        const formURL = "./assets/deferralAgreement.pdf"
        const pdfFormBytes = await fetch(formURL).then((res) => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(pdfFormBytes)
        const form = pdfDoc.getForm()
        const fields = form.getFields()
        var lesseeField = form.getTextField('Lessee');
        var lesseeAddressField = form.getTextField('Lessee Address');
        var lessorTitleField = form.getTextField('Lessee Title');
        var lesseeUnit = form.getTextField('UNIT');
        var driverCode = form.getTextField('OWNERCODE');
        var signDate = form.getTextField('Sign Date')
        lesseeField.setText(ownerData?.name)
        lesseeAddressField.setText(ownerData!.address + " " + ownerData!.city + " " + ownerData!.state)
        lessorTitleField.setText("Owner");
        lesseeUnit.setText("Unit: " + ownerData?.units[0]);
        driverCode.setText("Driver Code: " + localStorage.getItem('driverCode')!)
        signDate.setText(today);
        const lesseeSignatureBytes = await fetch(lesseeSignature).then((res) => res.arrayBuffer())
        const lesseeImageSignature = await pdfDoc.embedPng(lesseeSignatureBytes)
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        const pngDims = lesseeImageSignature.scale(0.2)
        firstPage.drawImage(lesseeImageSignature, {
            x: firstPage.getWidth() / 2 - pngDims.width / 2 + 65,
            y: firstPage.getHeight() / 2 - pngDims.height / 2 - 150,
            width: pngDims.width,
            height: pngDims.height,
        })
        const usefulData = await pdfDoc.saveAsBase64({dataUri: true})
        addDocument(usefulData, idNumber);
    }

    const save = () => {
        const signed = lesseeSigCanvas.current!.toDataURL()
        setLesseeSignature(signed)
        setShowLesseeSignature(!showLesseeSignature)
    }

    const clear = () => lesseeSigCanvas.current!.clear()

    async function addDocument(file: string, idNumber: number) {
        let tempFile = new Blob([(await b64toBlob(file))], {type: 'application/pdf'})
        ApiService.lpDocUpload('deferment', {
            filename: "deferralAgreement-" + localStorage.getItem('username')! + "-" + idNumber + ".pdf"}, 
            {file1: {
                fileName: "deferralAgreement-" + localStorage.getItem('username')! + "-" + idNumber + ".pdf",
                file: tempFile
            }}
            ).then(() => {
            toastStore.getState().setSuccessToast('Your deferment request has been submitted.');
            setShowSigning(false);
        });
    }

    function processAdvanceHistory(data: any) {
        let tempArray: any[] = [];
        let tempCurrentArray: any[] = [];
        data.forEach((element: any) => {
            if (element.approved === null) {
                const tempCurrentObj = element
                tempCurrentObj.date = element.date.replace('T', ' ').slice(0, -14);
                tempCurrentArray.push(tempCurrentObj)
            } else {
                const tempObj = element
                tempObj.date = element.date.replace('T', ' ').slice(0, -14);
                tempArray.push(tempObj)
            }
        });
        setCurrentDeferments(tempCurrentArray)
        setAdvances(tempArray);
    }

    async function getDMEmail() {
        ApiService.getDriverManager('load selection', {dmCode: localStorage.getItem('dmCode')!}).then(it => {console.log("dm email", it.email); submitRequest(it)});
    }

    async function submitRequest(dm: any) {
        if (lesseeSignature !== "") {
            ApiService.defermentCreate('deferment', {}).then(it => {
                toastStore.getState().setSuccessToast('Your deferment has been requested.');
                parsePDF(it);
                let emailList = dm.teamLeadEmail !== null ? ["sgregory@westernexp.com", "scarney@westernexp.com", dm.email, dm.teamLeadEmail] : ["sgregory@westernexp.com", "scarney@westernexp.com", dm.email];
                ApiService.sendEmail('deferment', {
                    to: emailList,
                    subject: 'Deferment Requested',
                    body: 'Driver ' + localStorage.getItem("username")?.toUpperCase() + " has request a deferment, go to http://software.westernexp.com/lp-admin-console/deferments to view the deferment"
                });
            });
        } 
        else {
            toastStore.getState().setToast('Please save a signature');
            return;
        }
    }

    function getContract(adv: any) {
        setDeferLink(`https://westernexpressmobile.com/lpdocs/deferralAgreement-${localStorage.getItem('username')?.toUpperCase()}-${adv.id}.pdf?rand=${Math.round(Math.random() * 10000000)}`)
        setDefermentModal(true);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Deferment</IonTitle>
                </IonToolbar>
            </IonHeader>
            {owesWestern ?
                <IonContent fullscreen>
                    <IonItemDivider style={{marginBottom: "2%"}} color="danger">
                        <IonLabel className="ion-text-wrap"><h1 style={{textAlign: "center", padding: "4px"}}> You are in the owing positions and cannot request a personal advance.</h1></IonLabel>
                    </IonItemDivider>
                    <IonButton expand="block" className="home" fill="outline" color="primary" strong size="large" href="/Drive">Back</IonButton>
                </IonContent> :

                <IonContent fullscreen>
                    <div style={{marginBottom: "9vh"}}>
                        <IonModal isOpen={showSigning} cssClass='my-custom-class'>
                            <IonCard style={{marginTop: "50%"}}>
                                <IonCardHeader>
                                    <IonCardTitle style={{color: "#0E375A", textAlign: "center", fontSize: "24px"}}><b>{header}</b></IonCardTitle>
                                    <IonItemGroup>
                                        <IonItem color="light" lines="none">
                                            <div className="item-container" style={{lineHeight: "20px"}}>
                                                <p>By signing this page you agree to the terms of the Deferral clause of your Lease Agreement with New Horizons, LLC</p>
                                            </div>
                                        </IonItem>
                                        <IonItem lines="none" color="light">
                                            <div className="item-container">
                                                <p>Lessee Signature</p>
                                                <div hidden={!showLesseeSignature}
                                                     style={{textAlign: "center", width: "90%", overflow: "hidden", backgroundColor: "white", margin: "auto", borderRadius: "20px"}}>
                                                    <SignaturePad
                                                        ref={lesseeSigCanvas}
                                                        canvasProps={{width: 500, height: 175, className: 'sigCanvas'}}
                                                    />
                                                </div>
                                                <div className="item-container" style={{display: "flex", fontSize: "17px"}}>
                                                    {showLesseeSignature ? <p onClick={save} style={{color: "#212121", margin: "8px 10px"}}>Save</p> :
                                                        <button onClick={save} className="button">Re-sign</button>}
                                                    {showLesseeSignature ? <p style={{color: "#5096FF", margin: "8px 10px"}} onClick={clear}>Clear</p> : null}
                                                </div>
                                            </div>
                                        </IonItem>
                                    </IonItemGroup>
                                    <IonButton expand="block" className="home" fill="outline" color="primary" strong size="large" onClick={function () {
                                        getDMEmail()
                                    }}>Submit</IonButton>
                                    <IonButton expand="block" className="home" fill="outline" color="primary" strong size="large" onClick={function () {
                                        setShowSigning(false); clear(); setShowLesseeSignature(!showLesseeSignature)
                                    }}>Cancel</IonButton>
                                </IonCardHeader>
                            </IonCard>
                        </IonModal>

                        <IonModal isOpen={defermentModal} cssClass='my-custom-class'>
                            <IonHeader>
                                <IonToolbar color="primary">
                                    <IonButtons slot="start">
                                        <IonButton onClick={function () {
                                            setDefermentModal(false)
                                        }}>
                                            <IonIcon slot="icon-only" icon={close}/>
                                        </IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent>
                                <div>
                                    <iframe style={{height: '100vh'}} src={deferLink} allowFullScreen/>
                                </div>
                            </IonContent>
                        </IonModal>
                        <IonList>
                            <IonItemGroup>
                                <IonItemDivider style={{marginBottom: "2%", marginTop: "25px", "--background": "#0E375A", color: "white"}}>
                                    <IonLabel style={{width: '100%'}} className="ion-text-wrap"><h1 style={{textAlign: "center", padding: "4px"}}>Request Deferment</h1> <p
                                        style={{textAlign: "center", padding: "4px"}}>If approved, your lease payment will not be subtracted from your lease on your next settlement.</p></IonLabel>
                                </IonItemDivider>
                                <IonButton id="western-button" expand="block" style={{background: "#ED8254", "--background": "#ED8254"}} onClick={() => setShowSigning(true)}>Request
                                    Deferment</IonButton>
                            </IonItemGroup>

                            <IonItemGroup>
                                <IonItemDivider style={{marginBottom: "2%", marginTop: "25px", "--background": "#0E375A", color: "white"}}>
                                    <IonLabel style={{width: '100%'}} className="ion-text-wrap"><h1 style={{textAlign: "center", padding: "4px"}}><b>Pending Deferments</b></h1></IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonGrid style={{textAlign: "center", width: "100%"}}>
                                        <IonRow>
                                            <IonCol>
                                                Date
                                            </IonCol>
                                            <IonCol>
                                                Status
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                                {currentDeferments.map(function (defer, idx) {
                                    return <IonItem key={idx}>
                                        <IonGrid style={{textAlign: "center", width: "100%"}}>
                                            <IonRow>
                                                <IonCol>
                                                    {defer.date.substr(5) + '-' + defer.date.substr(0, 4)}
                                                </IonCol>
                                                <IonCol>
                                                    {defer.approved === null ? "Pending" : ""}
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonItem>
                                })}
                            </IonItemGroup>

                            <IonItemGroup>
                                <IonItemDivider style={{marginBottom: "2%", marginTop: "25px", "--background": "#0E375A", color: "white"}}>
                                    <IonLabel style={{width: '100%'}} className="ion-text-wrap"><h1 style={{textAlign: "center", padding: "4px"}}><b>Deferment History</b></h1></IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonGrid style={{textAlign: "center", width: "100%"}}>
                                        <IonRow>
                                            <IonCol>
                                                Date
                                            </IonCol>
                                            <IonCol>
                                                Status
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                                {advances.map(function (adv, idx) {
                                    return <IonItem key={idx} onClick={e => {
                                        getContract(adv)
                                    }}>
                                        <IonGrid style={{textAlign: "center", width: "100%"}}>
                                            <IonRow>
                                                <IonCol>
                                                    {adv.date.substr(5) + '-' + adv.date.substr(0, 4)}
                                                </IonCol>
                                                <IonCol>
                                                    {adv.approved !== 0 ? "Approved" : "Rejected"}
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonItem>
                                })}
                            </IonItemGroup>
                        </IonList>
                    </div>
                </IonContent>
            }
        </IonPage>
    );
};

export default Deferment;

