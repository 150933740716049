import React from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonList, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import HelpDeskEntry from './HelpDeskEntry';


const Payroll: React.FC = () => {
    const questionsAndAnswers = [
        {
            question: `Why is my last check held/ When will I get my escrow?`,
            answer: `When you terminate your contract, everything goes on hold for 45 days for a final audit.`
        },
        {question: `When is the pay period?`, answer: `(Sunday-Saturday)`},
        {
            question: `Why does the amount on my settlement statements keep changing while I am in the middle of the term audit?`,
            answer: `During the audit process the balance forward may change do to Repair orders, unpaid loads, or fuel used while contracted.`
        },
        {
            question: `How much do I get paid a mile, and how to find it?`,
            answer: `Please refer to Appendix 3 of your hauling agreement.`
        },
        {
            question: `How do I get money out of escrow?`,
            answer: `Escrow is mostly used for maintenance repairs or lease payments.`
        },
        {
            question: `Do I not get paid empty miles?`,
            answer: `Please refer to Appendix 3 of your hauling agreement.`
        },
        {
            question: `How to set up their direct deposit?`,
            answer: `Please send an email to your driver agent with your bank account information, we will need a picture of an account, blank check, misc for verification.`
        },
    ]

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Settlement F.A.Q.</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen id="payroll-faq" style={{"--background": "#f4f5f8"}}>

                <IonList color="light" style={{height: "100vh"}}>
                    <IonItem lines="none" color="light">
                        <div className="item-container"><h1><strong>Frequently Asked Questions</strong></h1></div>
                    </IonItem>
                    {questionsAndAnswers.map((element, index) => <HelpDeskEntry key={index} element={element}/>)}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Payroll;
