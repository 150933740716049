import React, {createRef, useEffect, useState} from "react"
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonItemGroup, IonPage, IonTitle, IonToolbar} from "@ionic/react"
import {fill, PDFDocument} from 'pdf-lib'
import SignaturePad from "react-signature-canvas"
import ReactSignatureCanvas from "react-signature-canvas"
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";
import {useHistory} from "react-router-dom";
import { userInfo } from "os"
import { DriverProfile } from "../api/model/DriverProfile"
import { UserState, userStore } from "../api/AuthService"
import { stringify } from "querystring"
import { Disclosure } from "./Disclosure"
import NewDriverPacketModule from "../components/NewDriverPacket"
import { dateDoy } from "../Util"

interface ownerData {
    address: string,
    areaCode: number,
    city: string,
    email: string,
    name: string,
    phoneNumber: number,
    state: string,
    units: any
}

interface userDetails {
    id: string;
    birthdate: number;
    cdl: string;
    created: string;
    deleted: boolean;
    email: string;
    enabled: boolean;
    firstName: string;
    lastLogin: string;
    lastName: string;
    loginToken: string;
    password: string;
    phone: string;
    platform: string;
    resetToken: string;
    state: string;
    trainee_id: number;
    trainer_id: number;
    type: string;
    updated: string;
    username: string;
    zip: string;
    dmcode: string;
}



const today = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)

export const NewPacketForm = () => {

    const {userInfo}: { userInfo: DriverProfile } = userStore((state: UserState) => ({userInfo: state.driver}));
    const history = useHistory();
    const [lessee, setLessee] = useState<any>(""); 
    const [userData, setUserData] = useState<userDetails>();
    const [lesseeAddress, setLesseeAddress] = useState<any>("");
    const [lesseeSignature, setLesseeSignature] = useState<any>("");
    const [lesseeTitle, setLesseeTitle] = useState<any>("");
    const [showLesseeSignature, setShowLesseeSignature] = useState(true);
    const [beneficiary, setBeneficiary] = useState<any>("")
    const [relationship, setRelationship] = useState<any>("")
    const [ownerData, setOwnerData] = useState<ownerData>();
    const [unitData, setUnitData] = useState<any>();
    const [payeeCodeSelection, setPayeeCodeSelection] = useState<any>("");
    const [expemtCode, setExpemtCode] = useState<any>("");
    const [experience, setExperience] = useState<any>(0);
    const [showContract, setShowContract] = useState<string>("")
    const [emergencyName1, setEmergencyName1] = useState<any>("");
    const [emergencyNumber1, setEmergencyNumber1] = useState<any>("")
    const [emergencyName2, setEmergencyName2] = useState<any>("");
    const [emergencyNumber2, setEmergencyNumber2] = useState<any>("")
    const [zipCode, setZipCode] = useState<any>("")
    const [cdl, setCdl] = useState<any>("")
    const [displayOnly, setDisplayOnly] = useState<boolean>(true)
    const [signatureImage, setSignatureImage] = useState<string>("");
    const [showViewModal, setShowViewModal] = useState<boolean>(false);
    const [viewObject, setViewObject] = useState<any>()

    const [showDisclosure, setShowDisclosure] = useState<any>(false);
    const [intials, setIntials] = useState<string>("");
    const lesseeSigCanvas = createRef<ReactSignatureCanvas>()
    const [escrow, setEscrow] = useState("");
    const [ssn, setSsn] = useState<any>('')
    const [mf, setMF] = useState("male");
    const [taxClassification, setTaxClassification] = useState("individual");
    const [tax, setTax] = useState("C");
    const [ownerDataFull, setOwnerDataFull] = useState<boolean>(false);
    const [unitDataFull, setUnitDataFull] = useState<boolean>(false);
    const [llcClassClass, setllcClassClass] = useState("C")

    let currentDate = today.toISOString().split('T')[0]
    let currentYear = currentDate.slice(2, 4)

    useEffect(() => {
        getAgreementData()
        ApiService.unitGet("Operations", {unitNumber: userInfo.unit!}).then(it => {setUnitData(it); setUnitDataFull(true)})
        ApiService.ownerGet('Contracts', {}).then(it => {setOwnerData(it); setOwnerDataFull(true)});
        console.log("birthdate", convertDOYtoSQL(userInfo.birthdate.toString()));
    }, []);

    const save = () => {
        const signed = lesseeSigCanvas.current!.toDataURL()
        setLesseeSignature(signed)
        setShowLesseeSignature(!showLesseeSignature)
    }

    function convertDOYtoSQL(yyyyddd: any) {
        const year = parseInt(yyyyddd.substring(0, 4), 10);
        const dayOfYear = parseInt(yyyyddd.substring(4), 10);
        const date = new Date(year, 0, dayOfYear);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    

    const clear = () => lesseeSigCanvas.current!.clear()

    const fillForm = async () => {
        const formURL = "./assets/New Driver Packet 2024.pdf"
        const pdfFormBytes = await fetch(formURL).then((res) => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(pdfFormBytes)
        const form = pdfDoc.getForm()

        var driverName1 = form.getTextField('Driver Name 1');
        var driverIntials1 = form.getTextField('Driver Intials 1');
        var Ownercode = form.getTextField("Owner Code");
        var Drivercode = form.getTextField("Driver Code");
        var date1 = form.getTextField('Date 1');
        var date2 = form.getTextField('Date 2');
        var driverName2 = form.getTextField('Driver Name 2');
        var male = form.getTextField('Male');
        var female = form.getTextField('Female');
        var streetAddress = form.getTextField('Street Address');
        var city = form.getTextField('City');
        var state = form.getTextField('State');
        var zip = form.getTextField('Zip');
        var SSN1 = form.getTextField('SSN 1');
        var DOB = form.getTextField('DOB');
        var email = form.getTextField('Email Address');
        var homeTelephone = form.getTextField('Home Telephone Number');
        var cellTelephone = form.getTextField('Cell Telephone Number');
        var beneficiary1 = form.getTextField('Name of Beneficiary');
        var relationship1 = form.getTextField('Relationship of Beneficiary');
        var cdlNumber = form.getTextField('CDL Number');
        var yearsExperience = form.getTextField('Number of Years Experience');
        var date3 = form.getTextField('Date 3');
        var date4 = form.getTextField('Date 4');
        var driverIntials4 = form.getTextField('Driver Intials 4');
        var date5 = form.getTextField('Date 5');
        var driverName3 = form.getTextField('Driver Name 3');
        var tractor = form.getTextField('Tractor');
        var date6 = form.getTextField('Date 6');
        var driverName4 = form.getTextField('Driver Name 4');
        var individual = form.getCheckBox('Individual')
        var cCorporation = form.getCheckBox('C Corporation');
        var sCorporation = form.getCheckBox('S Corporation');
        var partnership = form.getCheckBox('Partnership');
        var trustEstate = form.getCheckBox('Trust/estate');
        var payeeCode = form.getTextField('Exempt payee code');
        var llc = form.getCheckBox('LLC');
        var llcClass = form.getTextField('LLC Class');
        var fatca = form.getTextField('Exempt FATCA');
        var addressStreet = form.getTextField('Address Street');
        var cityStateZip = form.getTextField('City State Zip');
        var SSN2 = form.getTextField('SSN 2');
        var date7 = form.getTextField('Date 7');
        var emergencyContactName1 = form.getTextField('Emergency Contact Name 1');
        var emergencyContactPhone1 = form.getTextField('Emergency Contact Phone 1');
        var emergencyContactName2 = form.getTextField('Emergency Contact Name 2');
        var emergencyContactPhone2 = form.getTextField('Emergency Contact Phone 2');

        driverName1.setText(ownerData!.name)
        driverIntials1.setText(intials)
        Ownercode.setText(localStorage.getItem('username')!)
        Drivercode.setText(localStorage.getItem('driverCode')!)
        date1.setText(currentDate.slice(5,10).toString())
        date2.setText(currentDate.slice(5,10).toString())
        driverName2.setText(ownerData!.name)
        mf === 'male' ? male.setText("X") : female.setText("X");
        streetAddress.setText(ownerData!.address)
        city.setText(ownerData!.city)
        state.setText(ownerData!.state)
        zip.setText(zipCode)
        SSN1.setText(ssn)
        DOB.setText(convertDOYtoSQL(userInfo.birthdate.toString()))
        email.setText(ownerData!.email)
        homeTelephone.setText(ownerData!.areaCode.toString() + ownerData!.phoneNumber.toString())
        cellTelephone.setText(ownerData!.areaCode.toString() + ownerData!.phoneNumber.toString())
        beneficiary1.setText(beneficiary);
        relationship1.setText(relationship);
        cdlNumber.setText(cdl)
        yearsExperience.setText(experience)
        date3.setText(currentDate.slice(5,10).toString())
        date4.setText(currentDate.slice(5,10).toString())
        driverIntials4.setText(intials)
        date5.setText(currentDate.slice(5,10).toString())
        driverName3.setText(ownerData!.name);
        tractor.setText(unitData!.unit.UNUNIT);
        date6.setText(currentDate.slice(5,10).toString());
        driverName4.setText(ownerData!.name);
        
        if (taxClassification === 'individual') {
            individual.check()
        } else if (taxClassification === 'c corporation') {
            cCorporation.check()
        } else if (taxClassification === 's corporation') {
            sCorporation.check()
        } else if (taxClassification === 'partnership') {
            partnership.check()
        } else if (taxClassification === 'trust/estate') {
            trustEstate.check()
        } else if (taxClassification === 'llc') {
            llc.check(); 
            llcClass.setText(llcClassClass)
        }
        payeeCode.setText(payeeCodeSelection)
        fatca.setText(expemtCode)
        addressStreet.setText(ownerData!.address)
        cityStateZip.setText(ownerData!.city + " " + ownerData!.state + "" + zip)
        SSN2.setText(ssn)
        date7.setText(currentDate.slice(5,10).toString())
        emergencyContactName1.setText(emergencyName1)
        emergencyContactPhone1.setText(emergencyNumber1)
        emergencyContactName2.setText(emergencyName2)
        emergencyContactPhone2.setText(emergencyNumber2)

        driverName1.enableReadOnly()
        driverIntials1.enableReadOnly()
        date1.enableReadOnly()
        date2.enableReadOnly()
        driverName2.enableReadOnly()
        male.enableReadOnly()
        female.enableReadOnly();
        streetAddress.enableReadOnly()
        city.enableReadOnly()
        state.enableReadOnly()
        zip.enableReadOnly()
        SSN1.enableReadOnly()
        DOB.enableReadOnly()
        email.enableReadOnly()
        homeTelephone.enableReadOnly()
        cellTelephone.enableReadOnly()
        beneficiary1.enableReadOnly()
        relationship1.enableReadOnly()
        cdlNumber.enableReadOnly()
        yearsExperience.enableReadOnly()
        date3.enableReadOnly()
        date4.enableReadOnly()
        driverIntials4.enableReadOnly()
        driverName3.enableReadOnly();
        tractor.enableReadOnly()
        date6.enableReadOnly();
        driverName4.enableReadOnly()
        date5.enableReadOnly()
        payeeCode.enableReadOnly()
        fatca.enableReadOnly()
        addressStreet.enableReadOnly()
        cityStateZip.enableReadOnly()
        SSN2.enableReadOnly()
        date7.enableReadOnly()
        emergencyContactName1.enableReadOnly()
        emergencyContactPhone1.enableReadOnly()
        emergencyContactName2.enableReadOnly()
        emergencyContactPhone2.enableReadOnly()
        individual.enableReadOnly()
        cCorporation.enableReadOnly()
        sCorporation.enableReadOnly()
        partnership.enableReadOnly()
        trustEstate.enableReadOnly()
        llcClass.enableReadOnly()

        const contractData = {
            driverName1: ownerData!.name,
            driverIntials1: intials, 
            date1: currentDate.slice(5,10).toString(), 
            sex: mf,
            streetAddress: ownerData!.address, 
            city: ownerData!.city, 
            state: ownerData!.state, 
            zip: zipCode, 
            SSN1: ssn, 
            DOB: convertDOYtoSQL(userInfo.birthdate.toString()), 
            email: ownerData!.email, 
            homeTelephone: ownerData!.areaCode.toString() + ownerData!.phoneNumber.toString(),
            cellTelephone: ownerData!.areaCode.toString() + ownerData!.phoneNumber.toString(),
            beneficiary1: beneficiary, 
            relationship1: relationship, 
            cdlNumber: cdl,
            yearsExperience: experience,
            tractor: unitData!.unit.UNUNIT,
            payeeCode: payeeCodeSelection, 
            fatca: expemtCode,
            cityStateZip: ownerData!.city + " " + ownerData!.state + "" + zip,
            emergencyContactName1: emergencyName1, 
            emergencyContactPhone1: emergencyNumber1, 
            emergencyContactName2: emergencyName2, 
            emergencyContactPhone2: emergencyNumber2
        }


        const lesseeSignatureBytes = await fetch(lesseeSignature).then((res) => res.arrayBuffer())
        const lesseeImageSignature = await pdfDoc.embedPng(lesseeSignatureBytes)

        const pages = pdfDoc.getPages()
        const firstPage = pages[0];
        const secondPage = pages[12];
        const thirdPage = pages[13];
        const fourthPage = pages[14];
        const pngDims = lesseeImageSignature.scale(0.2)
        firstPage.drawImage(lesseeImageSignature, {
            x: firstPage.getWidth() / 2 - pngDims.width - 135,
            y: firstPage.getHeight() / 2 - pngDims.height - 175,
            width: pngDims.width,
            height: pngDims.height,
        })
        secondPage.drawImage(lesseeImageSignature, {
            x: secondPage.getWidth() / 2 - pngDims.width - 85,
            y: secondPage.getHeight() / 2 - pngDims.height - 375,
            width: pngDims.width,
            height: pngDims.height,
        })
        thirdPage.drawImage(lesseeImageSignature, {
            x: thirdPage.getWidth() / 2 - pngDims.width / 2 + 85,
            y: thirdPage.getHeight() / 2 - pngDims.height + 55,
            width: pngDims.width,
            height: pngDims.height,
        })
        fourthPage.drawImage(lesseeImageSignature, {
            x: thirdPage.getWidth() / 2 - pngDims.width - 75,
            y: thirdPage.getHeight() / 2 - pngDims.height - 140,
            width: pngDims.width,
            height: pngDims.height,
        })
        var sig1 = form.getTextField('Driver Signature 1');
        var sig2 = form.getSignature('Driver Signature 2');
        var sig3 = form.getSignature('Driver Signature 3');
        var sig4 = form.getTextField('Driver Signature 4');
        sig1.enableReadOnly();
        sig2.enableReadOnly();
        sig3.enableReadOnly();
        sig4.enableReadOnly();
        const usefulData = await pdfDoc.saveAsBase64({dataUri: true})
        sendAgreementData(contractData, lesseeSignatureBytes);
        submit(usefulData);
    }

    const b64toBlob = (base64:any) => 
        fetch(base64).then(res => res.blob())

    async function submit(data: string) {
        const newData = new Blob([(await b64toBlob(data))],{type: 'application/pdf'})
        ApiService.sendFileEmail("New Driver Packet", {
            email: `scarney@westernexp.com, mpiras@westernexp.com, lpdocuments@westernexp.com, jmorris@westernexp.com, ${ownerData!.email.trim()}`,
            subject: `New Driver Packet ${localStorage.getItem('username')}.pdf`,
        },
        {
          file1: {
            fileName: `New Driver Packet ${localStorage.getItem('username')}.pdf`,
            file: newData,
          }
        })
        .then(() => {ApiService.sendEventCommand("New Driver Packet", "eventCheck", "User submitted an agreement")})
        .then(() => {toastStore.getState().setSuccessToast('Success. Your signature was submitted.');redirectPage()});
    }

    const redirectPage = () => {
        setTimeout(function() {
          window.location.replace('./contracts');
        }, 3000)
      }

    function getAgreementData() {
        ApiService.agreementDataGet('new driver packet', {ownerCode: localStorage.getItem('username')!, contractType: "newdriver"})
        .then(it => {
            parseFormData(it)
        })
        .catch(it => {
            console.log(it);
            setDisplayOnly(false);
        })
    }

    function parseFormData(results: any) {
        let tempData: any = {}
        if (results.data !== undefined) {
            tempData = results
            console.log("results", tempData)
            setShowContract(tempData.contractDate)
            setViewObject(JSON.parse(tempData.data))
            setSignatureImage(tempData.signatureData)
            setShowViewModal(true)
        } else {
            setDisplayOnly(false);
        }
        console.log("tempData", viewObject)
    }

    function sendAgreementData(dataBlock: any, signature: any) {
        let sigData: any = bufferToB64(signature)
        ApiService.agreementDataUpload('new driver packet', {ownerCode: localStorage.getItem('username')!, contractType: "newdriver", contractDate: "2024-1", data: JSON.stringify(dataBlock), signatureData: sigData})
    }
    
    function bufferToB64(sigData: any) {
        const binary: any = new Uint8Array(sigData);
        let base64Data: any = `data:image/png;base64,` + btoa(String.fromCharCode(...binary));
        return base64Data;
    }

    return (
        <div style={{margin: "0px 15px", overflow: "scroll", height: "100vh"}}>
            <IonItem color="light" lines="none">
                <div className="item-container">
                    <p style={{textAlign: "center", fontSize: "18px"}}>New Driver Packet</p>
                </div>
            </IonItem>
            <IonItem color="light" lines="none">
                {displayOnly ?
                    <div className="item-container">
                        {showViewModal ? <NewDriverPacketModule viewObject={viewObject} signatureData={signatureImage}/> : null}
                    </div> :
                    <div className="item-container">
                        {ownerDataFull && unitDataFull ? 
                        <NewDriverPacketModule 
                        ownerData={ownerData} 
                        unitData={unitData} 
                        intials={intials} 
                        mf={mf} 
                        yearsExperience={experience} 
                        cdlNumber={userData} 
                        ssn={ssn}
                        emergencyContactName1={emergencyName1}
                        emergencyContactPhone1={emergencyNumber1}
                        emergencyContactName2={emergencyName2}
                        emergencyContactPhone2={emergencyNumber2}
                        date={currentDate.slice(5,10).toString()} 
                        year={currentYear.toString()}/> 
                        : null}
                    </div> }
            </IonItem>
            { !displayOnly ? <>
            <IonItem color="light" lines="none">
                <div className="item-container">
                    <div className="input-container">
                        <input id="name" className="input" type="text" placeholder=" " value={intials} onChange={e => setIntials(e.target.value!)}/>
                        <p className="placeholder">Intials</p>
                    </div>
                </div>
            </IonItem>
            <IonItem color="light" lines="none">
                <div className="item-container">
                    <div className="input-container">
                        <input id="name" className="input" type="number" placeholder=" " value={zipCode} onChange={e => setZipCode(e.target.value!)}/>
                        <p className="placeholder">Zip Code</p>
                    </div>
                </div>
            </IonItem>
            <IonItem color="light" lines="none">
                <div className="item-container">
                    <div className="input-container">
                        <input id="name" className="input" type="number" placeholder=" " value={cdl} onChange={e => setCdl(e.target.value!)}/>
                        <p className="placeholder">CDL Number</p>
                    </div>
                </div>
            </IonItem>
            {showDisclosure ? <Disclosure /> : null}
            <IonItem color="light" lines="none">
                <div style={{display: "grid", marginLeft: "10px"}}>
                    <div>Sex</div>
                    <div className='item-container'>
                        <select className="input" onChange={(e) => {setMF(e.target.value)}} style={{height: "20px", width: "90px", textAlign: "center"}}>
                            <option value={"male"}>Male</option>
                            <option value={"female"}>Female</option>
                        </select>
                    </div>
                </div>
            </IonItem>
            <IonItem color="light" lines="none">
                <div className="item-container">
                    <div className="input-container">
                        <input id="name" className="input" type="password" placeholder=" " value={ssn} onChange={e => setSsn(e.target.value!)}/>
                        <p className="placeholder">SSN</p>
                    </div>
                </div>
            </IonItem>
            <IonItem color="light" lines="none">
                <div className="item-container">
                    <div className="input-container">
                        <input id="name" className="input" type="text" placeholder=" " value={experience} onChange={e => setExperience(e.target.value!)}/>
                        <p className="placeholder">Years Experience</p>
                    </div>
                </div>
            </IonItem>
            <IonItem color="light" lines="none">
                <div className="item-container">
                <div className="input-container">
                    <input id="bene" className="input" type="text" placeholder=" " value={beneficiary} onChange={e => setBeneficiary(e.target.value!)}/>
                    <p className="placeholder" >Beneficiary</p>
                </div>
                </div>
            </IonItem>
            <IonItem color="light" lines="none">
                <div className="item-container">
                <div className="input-container">
                    <input id="bene" className="input" type="text" placeholder=" " value={relationship} onChange={e => setRelationship(e.target.value!)}/>
                    <p className="placeholder" >Relationship to Passenger</p>
                </div>
                </div>
            </IonItem>
            <IonItem color="light" lines="none">
                <div style={{display: "grid", marginLeft: "10px"}}>
                    <div>Tax Class</div>
                    <div className='item-container'>
                        <select className="input" onChange={(e) => {setTax(e.target.value)}} style={{height: "20px", width: "180px", textAlign: "center"}}>
                            <option value={"individual"}>Individual</option>
                            <option value={"c corporation"}>C corporation</option>
                            <option value={"c corporation"}>S corporation</option>
                            <option value={"partnership"}>Partnership</option>
                            <option value={"trust/estate"}>Individual</option>
                            <option value={"llc"}>LLC</option>
                        </select>
                    </div>
                </div>
            </IonItem>
            {tax === 'llc' ? <IonItem color="light" lines="none">
                <div style={{display: "grid", marginLeft: "10px"}}>
                    <div>LLC Class</div>
                    <div className='item-container'>
                        <select className="input" onChange={(e) => {setEscrow(e.target.value)}} style={{height: "20px", width: "90px", textAlign: "center"}}>
                            <option value={"C"}>C</option>
                            <option value={"S"}>S</option>
                            <option value={"P"}>P</option>
                        </select>
                    </div>
                </div>
            </IonItem>: null }
            <IonItem color="light" lines="none">
                <div className="item-container">
                <div className="input-container">
                    <input id="bene" className="input" type="text" placeholder="" value={payeeCodeSelection} onChange={e => setPayeeCodeSelection(e.target.value!)}/>
                    <p className="placeholder" >Exempt Payee Code (if any)</p>
                </div>
                </div>
            </IonItem>
            <IonItem color="light" lines="none">
                <div className="item-container">
                <div className="input-container">
                    <input id="bene" className="input" type="text" placeholder="" value={expemtCode} onChange={e => setExpemtCode(e.target.value!)}/>
                    <p className="placeholder" >Exemption from FATCA code (if any)</p>
                </div>
                </div>
            </IonItem>

            {/* ======= Emergency Contacts ======= */}
            <IonItem color="light" lines="none">
                <div className="item-container">
                <div className="input-container">
                    <input id="bene" className="input" type="text" placeholder="" value={emergencyName1} onChange={e => setEmergencyName1(e.target.value!)}/>
                    <p className="placeholder">Emergeny Contact Name 1</p>
                </div>
                </div>
            </IonItem>
            <IonItem color="light" lines="none">
                <div className="item-container">
                <div className="input-container">
                    <input id="bene" className="input" type="text" placeholder="" value={emergencyNumber1} onChange={e => setEmergencyNumber1(e.target.value!)}/>
                    <p className="placeholder">Emergency Contact Number 1</p>
                </div>
                </div>
            </IonItem>
            <IonItem color="light" lines="none">
                <div className="item-container">
                <div className="input-container">
                    <input id="bene" className="input" type="text" placeholder="" value={emergencyName2} onChange={e => setEmergencyName2(e.target.value!)}/>
                    <p className="placeholder">Emergency Contact Name 2</p>
                </div>
                </div>
            </IonItem>
            <IonItem color="light" lines="none">
                <div className="item-container">
                <div className="input-container">
                    <input id="bene" className="input" type="text" placeholder="" value={emergencyNumber2} onChange={e => setEmergencyNumber2(e.target.value!)}/>
                    <p className="placeholder">Emergency Contact Number 2</p>
                </div>
                </div>
            </IonItem>

            <IonItemGroup>
                <IonItem lines="none" color="light">
                    <div className="item-container">
                        <p>By signing, I agree to this document, the <div style={{color: "#5096FF", textDecoration: "underline"}} onClick={() => setShowDisclosure(true)}>Consumer Disclosure</div>and to utilize electronic signatures</p>
                    </div>
                </IonItem>
                <IonItem lines="none" color="light">
                    <div className="item-container">
                        <p>Owner Signature</p>
                        <div hidden={!showLesseeSignature} style={{textAlign: "center", width: "90%", overflow: "hidden", backgroundColor: "white", margin: "auto", borderRadius: "20px"}}>
                            <SignaturePad
                                ref={lesseeSigCanvas}
                                canvasProps={{width: 500, height: 175, className: 'sigCanvas'}}
                            />
                        </div>
                        <div className="item-container" style={{display: "flex", fontSize: "17px"}}>
                            {showLesseeSignature ? <p onClick={save} style={{color: "#212121", margin: "8px 10px"}}>Save</p> : <button onClick={save} className="button">Re-sign</button>}
                            {showLesseeSignature ? <p style={{color: "#5096FF", margin: "8px 10px"}} onClick={clear}>Clear</p> : null}
                        </div>
                    </div>
                </IonItem>
            </IonItemGroup>
            <IonItemGroup style={{marginBottom: "30%"}}>
                <IonItem lines="none" color="light">
                    <div className="item-container">
                        <button className="western-button submit" onClick={() => {
                            if (lesseeSignature === "") {
                                toastStore.getState().setToast('Please save signature.');
                            } else if (intials === "") {
                                toastStore.getState().setToast('Please complete all fields.');
                            } else {
                                fillForm();
                                toastStore.getState().setSuccessToast('Your form is being submitted');
                            }
                        }}>SUBMIT
                        </button>
                    </div>
                </IonItem>
            </IonItemGroup>
            </>: null }
        </div>
    )
}

export default NewPacketForm
